<template>
    <div class="container">
        <div class="panel">

            <Signal />

            <Alert />

            <Query />

            <Category :list="category" />
        </div>
    </div>
</template>

<script>

import Signal from './components/signal'
import Query from './components/query'
import Alert from '@/components/alert'
import Category from './components/category'

import { fetchList } from '@/api/article'

export default {


    components: {
        Signal,
        Query,
        Alert,
        Category,
    },

    data() {
        return {
            list: [],
            category: [],
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    category: "fzjz",
                    page: 1,
                    rows: 10,
                    // face: 1
                },
                    {
                        category: "qxbk",
                        page: 1,
                        rows: 100,
                        }
                ]
            }).then(res => {
                if (Array.isArray(res) && res.length > 0) {
                    if (res[0].data && Array.isArray(res[0].data.list)) {
                        this.list = res[0].data.list;
                    }
                    if (res[1].data && Array.isArray(res[1].data.list)) {
                        this.category = res[1].data.list;
                    }
                }
            })
        }
    }


}
</script>

<style lang="less" scoped>

</style>