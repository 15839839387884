export default {

    methods: {
        renderDistrict({name = '江苏省',    fillColor = '#238b88',
        strokeColor = '#238b88'}) {
            const { AMap } = window;
            if (!AMap) {
                return;
            }
            const that = this;
            AMap.plugin("AMap.DistrictSearch", function () {
                var districtSearch = new AMap.DistrictSearch({
                    level: "province",
                      extensions: 'all',
                });

                districtSearch.search(name, function (status, result) {
                    console.log('result', result)
                    if (!result.districtList[0]) return;
                    var bounds = result.districtList[0].boundaries;

                    if (bounds) {
                        for (var i = 0, l = bounds.length; i < l; i++) {
                            //生成行政区划polygon
                            // var polygon =
                                new AMap.Polygon({
                                map: that.map,
                                strokeWeight: 2,
                                path: bounds[i],
                                fillOpacity: 0.01,
                                fillColor,
                                strokeColor,
                            })
                            // polygons.push(polygon)
                        }
                        // 地图自适应
                        // that.map.setFitView()
        
                    }

                });
            });

        },
    }

}