<template>
    <Card title="气象百科" url="/article-category?name=防灾减灾">

        <div class="list">
            <a href="#" v-for="item in list" :key="item.code" class="item" @click.prevent="openArticleDetail(item)">
                {{item.title}}
            </a>
        </div>

    </Card>
</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    // data() {
    //     return {
    //         list: [{
    //             title: '暴风雪',
    //             path: '',
    //         },{
    //             title: '暴雨',
    //             path: '',
    //         },{
    //             title: '大风',
    //             path: '',
    //         },{
    //             title: '厄尔尼诺',
    //             path: '',
    //         },{
    //             title: '副热带高压',
    //             path: '',
    //         },{
    //             title: '高温浪潮',
    //             path: '',
    //         },{
    //             title: '寒潮',
    //             path: '',
    //         },{
    //             title: '江淮气旋',
    //             path: '',
    //         },{
    //             title: '雷电',
    //             path: '',
    //         },{
    //             title: '冷空气强度',
    //             path: '',
    //         },{
    //             title: '霾',
    //             path: '',
    //         },{
    //             title: '梅雨',
    //             path: '',
    //         },{
    //             title: '强对流天气的种类',
    //             path: '',
    //         },{
    //             title: '人工增雨',
    //             path: '',
    //         },{
    //             title: '沙尘暴',
    //             path: '',
    //         },{
    //             title: '霜冻',
    //             path: '',
    //         },{
    //             title: '台风',
    //             path: '',
    //         }, {
    //             title: '雾',
    //             path: ''
    //         }]
    //     }
    // },
}
</script>

<style lang="less" scoped>

.list {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 12px;
    a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>