<template>
  <div class="wrapper">
    <Card title="预警信号" :extra="false">
      <div id="signal"></div>
    </Card>
  </div>
</template>

<script>
// import district from "@/mixins/district";
import { mapState } from "vuex";

export default {
  // mixins: [district],

  data() {
    return {
      markers: [],
    };
  },

  computed: {
    ...mapState("alert", ["list"]),
  },
  watch: {
    list() {
      this.setMarkers();
    },
  },

  mounted() {
    this.initmap();
    // this.renderDistrict();

    this.setMarkers();
  },

  destroyed() {

    this.map?.clearOverLays();

  },

  methods: {
    initmap() {

      const { T } = window;
      if (!T) return;
      const map = new T.Map('signal');
      map.centerAndZoom(new T.LngLat(119.14268709684, 33.617462694202), 7);
      const control = new T.Control.Zoom();
      map.addControl(control);
      this.map = map;

    },

    setMarkers() {


      if (!this.list.length) return;

      const { T } = window;
      if (!T) return;


        this.list.forEach(item => {
        
        const icon = new T.Icon({
          iconUrl: `https://adm.ac.cn/static/img/warning/${item.alarmType}_${item.alarmLevel}.png`,
          iconSize: new T.Point(32, 32),
        });

        const marker = new T.Marker(new T.LngLat(item.lon, item.lat), { icon: icon });
        this.map?.addOverLay(marker);

        const win = new T.InfoWindow();
        const sContent = `
    <div class='info-window'>
      <div class='info-window-header'>
        <span>
          详细信息
        </span>
      </div>
      <div class='info-window-content'>
          <span style='color: #e44e35'>
          预警区域：
          </span>
      ${item.province}${item.city}${item.areaName}</div>
      <div class='info-window-content'>
             <span style='color: #e44e35'>
          预警等级：
          </span>
      ${item.alarmType}${item.alarmLevel}</div>
      <div class='info-window-content'>
               <span style='color: #e44e35'>
          预警内容：
          </span>
      ${item.issueContent}</div>
      <div class='info-window-content'>
                <span style='color: #e44e35'>
          发布时间：
          </span>
      ${item.issuetime}</div>
    </div>
   `;

        win.setContent(sContent);
        marker.addEventListener("click", function () {
          marker.openInfoWindow(win);
        });


      })

    },

  },
};
</script>

<style lang="less" scoped>
.wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
}
#signal {
  height: 300px;
}
</style>