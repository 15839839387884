<template>
  <div class="wrapper">
    
            <Card title="避难信息查询">
                     <template v-slot:extra>
            <div>
                          <a-select
            placeholder="请选择"
            size='small'
            style="width: 90px"
            v-model="selectedCity"
          >
            <a-select-option
              v-for="item in list"
              :key="item.name"
              :value="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
            </div>
          </template>
      <div id="query"></div>
            </Card>
  </div>

</template>

<script>
import district from '@/mixins/district'
import cityData from './data'
import markerImage from './marker.png'
import { mapState } from 'vuex'
export default {
      mixins: [district],

    data() {
        return {
            cityList: [
                '南京',
                '无锡',
                '苏州',
                '常州',
                '徐州',
                '泰州',
                '扬州',
                '淮安',
                '南通',
                '连云港',
                '宿迁',
                '镇江',
                '盐城'
            ],
            selectedCity: '',
            markers: [],
        }
  },

  computed: {
    ...mapState('city', ['list', 'city']),
    },


    watch: {
      selectedCity() {

        
        this.map?.clearOverLays();

               const arr = cityData.filter(item => item.cityname && item.cityname.indexOf(this.selectedCity) > -1)

      if (!arr.length) return;


      const { T } = window;
      if (!T) return;

          const icon = new T.Icon({
        iconUrl: markerImage,
        iconSize: new T.Point(32, 32),
      });


      arr.forEach(item => {
    
        const marker = new T.Marker(new T.LngLat(item.longitude, item.latitude), { icon: icon });
        this.map?.addOverLay(marker);

        const win = new T.InfoWindow();

           const sContent = `
    <div class='info-window'>
      <div class='info-window-header'>
        <span>
          ${item.asylumname}
        </span>
      </div>
      <div class='info-window-content'>地址：${item.asylumaddress}</div>
      <div class='info-window-content'>管理单位：${item.managementunit}</div>
    </div>
   `


        win.setContent(sContent);
        marker.addEventListener("click", function () {
          marker.openInfoWindow(win);
        });


      })

        this.map?.panTo(new T.LngLat(arr[0].longitude, arr[0].latitude));
    

      }
    },


      mounted() {
        this.initmap();

        this.selectedCity = this.city;
  },

  destroyed() {

        this.map?.clearOverLays();

  },

  methods: {
    initmap() {


      const cityObj = this.list.find(item => item.name === this.city)

      const { T } = window;
      if (!T) return;
      const map = new T.Map('query');
      map.centerAndZoom(new T.LngLat(cityObj.lng, cityObj.lat), 9);
      const control = new T.Control.Zoom();
      map.addControl(control);

      this.map = map;


    },

  },
}
</script>



<style lang="less" scoped>
.wrapper {
      grid-column-start: 1;
    grid-column-end: 3;
}
#query {
    height: 300px;
}
</style>