export default [{
    "cityname": "邳州市",
    "asylumname": "邳州市体委操场（附小操场）",
    "asylumaddress": "老城区居住片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "附小",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.90306,
    "latitude": 34.402946
},
{
    "cityname": "邳州市",
    "asylumname": "邳州市运河中学北校区体育场　",
    "asylumaddress": "老城区居住片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "运河中学　",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.960812,
    "latitude": 34.344896
},
{
    "cityname": "邳州市",
    "asylumname": "邳州市明德实验学校体育场",
    "asylumaddress": "老城区居住片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.7,
    "asylumnumber": 1,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "明德学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.980233,
    "latitude": 34.338981
},
{
    "cityname": "邳州市",
    "asylumname": "邳州市职教中心操场",
    "asylumaddress": "老城区居住片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "职教中心",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.985847,
    "latitude": 34.307649
},
{
    "cityname": "邳州市",
    "asylumname": "邳州市客运站停车场及绿地",
    "asylumaddress": "老城区居住片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "客运站",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.90306,
    "latitude": 34.402946
},
{
    "cityname": "新沂",
    "asylumname": "新沂迎宾公园",
    "asylumaddress": "钟吾南路",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 6,
    "asylumnumber": 5,
    "basicfacilitymatch": "较齐全",
    "commonfacilitymatch": "较齐全",
    "comprebasicfacilitymatch": "较齐全",
    "managementunit": "人民公园",
    "managementunithead": "郭广运",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 118.356391,
    "latitude": 34.336364
},
{
    "cityname": "新沂",
    "asylumname": "新沂人民公园",
    "asylumaddress": "市府西路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 2,
    "basicfacilitymatch": "较齐全",
    "commonfacilitymatch": "较齐全",
    "comprebasicfacilitymatch": "较齐全",
    "managementunit": "人民公园",
    "managementunithead": "郭广运",
    "createtime": null,
    "ischeck": "是",
    "longitude": 118.512789,
    "latitude": 34.325923
},
{
    "cityname": "新沂",
    "asylumname": "新沂体育场",
    "asylumaddress": "新华路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 3,
    "basicfacilitymatch": "较齐全",
    "commonfacilitymatch": "较齐全",
    "comprebasicfacilitymatch": "较齐全",
    "managementunit": "体育场",
    "managementunithead": "贾磊",
    "createtime": null,
    "ischeck": "是",
    "longitude": 118.344121,
    "latitude": 34.284443
},
{
    "cityname": "新沂",
    "asylumname": "新沂站前广场",
    "asylumaddress": "火车站前",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": "较齐全",
    "commonfacilitymatch": "较齐全",
    "comprebasicfacilitymatch": "较齐全",
    "managementunit": "园林处",
    "managementunithead": "王振和",
    "createtime": null,
    "ischeck": "是",
    "longitude": 118.35618,
    "latitude": 34.385484
},
{
    "cityname": "新沂",
    "asylumname": "新沂神山外语学校",
    "asylumaddress": "学校院内",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "较齐全",
    "commonfacilitymatch": "较齐全",
    "comprebasicfacilitymatch": "较齐全",
    "managementunit": "神山学校",
    "managementunithead": "蔡强",
    "createtime": null,
    "ischeck": "是",
    "longitude": 118.402692,
    "latitude": 34.369799
},
{
    "cityname": "新沂",
    "asylumname": "新沂一中、职教中心操场",
    "asylumaddress": "学校院内",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 10,
    "asylumnumber": 5,
    "basicfacilitymatch": "较齐全",
    "commonfacilitymatch": "较齐全",
    "comprebasicfacilitymatch": "较齐全",
    "managementunit": "一中、职教中心",
    "managementunithead": "郭振京、徐洪熙",
    "createtime": null,
    "ischeck": "是",
    "longitude": 118.344126,
    "latitude": 34.356272
},
{
    "cityname": "铜山区",
    "asylumname": "铜山区实验小学操场",
    "asylumaddress": "实验小学",
    "placelevel": "固定",
    "placetype": "学校场地",
    "asylumarea": 1.5,
    "asylumnumber": 0.8,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.190874,
    "latitude": 34.199445
},
{
    "cityname": "铜山区",
    "asylumname": "铜山会议中心广场",
    "asylumaddress": "铜山新区",
    "placelevel": "固定",
    "placetype": "广场",
    "asylumarea": 1.7,
    "asylumnumber": 0.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.177363,
    "latitude": 34.188797
},
{
    "cityname": "铜山区",
    "asylumname": "铜山中学操场",
    "asylumaddress": "铜山中学",
    "placelevel": "固定",
    "placetype": "学校场地",
    "asylumarea": 2.6,
    "asylumnumber": 1.8,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 118.885822,
    "latitude": 31.700398
},
{
    "cityname": "铜山区",
    "asylumname": "中国矿业大学操场",
    "asylumaddress": "中国矿业大学",
    "placelevel": "固定",
    "placetype": "学校场地",
    "asylumarea": 5.9,
    "asylumnumber": 2.9,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 116.352613,
    "latitude": 40.003165
},
{
    "cityname": "铜山区",
    "asylumname": "九州大学及客运站",
    "asylumaddress": "九州大学",
    "placelevel": "固定",
    "placetype": "绿地",
    "asylumarea": 2.5,
    "asylumnumber": 1.3,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 114.425012,
    "latitude": 30.539528
},
{
    "cityname": "铜山区　",
    "asylumname": "铜山区泉新路迎宾公园",
    "asylumaddress": "泉新路　",
    "placelevel": "固定",
    "placetype": "绿地　",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有　",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局　",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.17811,
    "latitude": 34.212725
},
{
    "cityname": "铜山区　",
    "asylumname": "铜山区娇山湖公园　",
    "asylumaddress": "　铜山新区",
    "placelevel": "固定",
    "placetype": "绿地",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有　",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局　",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.229402,
    "latitude": 34.348982
},
{
    "cityname": "铜山区",
    "asylumname": "铜山区驿城中学，小学操场",
    "asylumaddress": "驿城中学，小学",
    "placelevel": "固定",
    "placetype": "学校场地",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.229402,
    "latitude": 34.348982
},
{
    "cityname": "铜山区",
    "asylumname": "铜山区江苏电大操场",
    "asylumaddress": "江苏电大",
    "placelevel": "固定",
    "placetype": "学校场地",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.229402,
    "latitude": 34.348982
},
{
    "cityname": "铜山区",
    "asylumname": "铜山区三堡中学",
    "asylumaddress": "三堡中学",
    "placelevel": "固定",
    "placetype": "学校场地",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.229402,
    "latitude": 34.348982
},
{
    "cityname": "铜山区",
    "asylumname": "铜山区无名山公园",
    "asylumaddress": "无名山",
    "placelevel": "固定",
    "placetype": "公园",
    "asylumarea": 18,
    "asylumnumber": 5,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": null,
    "managementunit": "地震局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.184093,
    "latitude": 34.184786
},
{
    "cityname": "贾汪",
    "asylumname": "玉龙湾公园及群众文化活动中心",
    "asylumaddress": "贾汪新城区",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 15,
    "asylumnumber": 5,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.468195,
    "latitude": 34.440491
},
{
    "cityname": "贾汪",
    "asylumname": "贾汪人民公园",
    "asylumaddress": "贾汪老城区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 6,
    "asylumnumber": 3,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": null,
    "longitude": 117.459973,
    "latitude": 34.45746
},
{
    "cityname": "徐州",
    "asylumname": "徐州三环北路防灾公园",
    "asylumaddress": "三环北路以南，丁万河南北两岸",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 5.4,
    "asylumnumber": 2.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "鼓楼区",
    "managementunithead": "黄晓鉴",
    "createtime": "2013.5",
    "ischeck": "是",
    "longitude": 117.173652,
    "latitude": 34.325016
},
{
    "cityname": "徐州",
    "asylumname": "徐州九龙湖公园",
    "asylumaddress": "徐州市鼓楼区中山北路和二环北路的交叉口",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "鼓楼区",
    "managementunithead": "黄晓鉴",
    "createtime": "2005",
    "ischeck": "是",
    "longitude": 117.192442,
    "latitude": 34.29129
},
{
    "cityname": "徐州",
    "asylumname": "徐州彭城广场",
    "asylumaddress": "江苏省徐州市鼓楼区中山北路",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 2.4,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "云龙区",
    "managementunithead": "李光",
    "createtime": "1998.6",
    "ischeck": "是",
    "longitude": 117.193396,
    "latitude": 34.270763
},
{
    "cityname": "徐州",
    "asylumname": "徐州人民广场",
    "asylumaddress": "淮海西路、纺织西路叉路口东北角",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "2008",
    "ischeck": "是",
    "longitude": 117.165429,
    "latitude": 34.269003
},
{
    "cityname": "徐州",
    "asylumname": "徐州泉山公园及周边",
    "asylumaddress": "徐州市三环南路",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 5,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "1992",
    "ischeck": "是",
    "longitude": 117.174328,
    "latitude": 34.223932
},
{
    "cityname": "徐州",
    "asylumname": "徐州云龙公园",
    "asylumaddress": "徐州市区王陵路南侧",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 12.6,
    "asylumnumber": 6.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "1982",
    "ischeck": "是",
    "longitude": 117.186026,
    "latitude": 34.260057
},
{
    "cityname": "徐州",
    "asylumname": "徐州东坡休闲广场",
    "asylumaddress": "江苏省徐州市泉山区泰山路",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "2008",
    "ischeck": "是",
    "longitude": 117.192184,
    "latitude": 34.248158
},
{
    "cityname": "徐州",
    "asylumname": "徐州西苑体育公园",
    "asylumaddress": "江苏省徐州市泉山区春雨花园东侧",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 1.1,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 117.160054,
    "latitude": 34.277255
},
{
    "cityname": "徐州",
    "asylumname": "徐州科技广场",
    "asylumaddress": "江苏省徐州市泉山区解放南路",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 10.5,
    "asylumnumber": 5.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 117.188107,
    "latitude": 34.271553
},
{
    "cityname": "徐州",
    "asylumname": "徐州金龙湖公园",
    "asylumaddress": "206国道与新城区汉源大道交叉口东南方",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 7.8,
    "asylumnumber": 3.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "开发区",
    "managementunithead": "刘同富",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 117.188107,
    "latitude": 34.271553
},
{
    "cityname": "徐州",
    "asylumname": "徐州大庙公园",
    "asylumaddress": " 兴镇北路(近房亭小区",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 8.3,
    "asylumnumber": 4.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "鼓楼区",
    "managementunithead": "黄晓鉴",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 117.376479,
    "latitude": 34.270854
},
{
    "cityname": "徐州",
    "asylumname": "徐州鼓楼二中",
    "asylumaddress": "江苏省徐州市鼓楼区河清路",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "鼓楼区",
    "managementunithead": "黄晓鉴",
    "createtime": "1948",
    "ischeck": "是",
    "longitude": 117.296129,
    "latitude": 34.30141
},
{
    "cityname": "徐州",
    "asylumname": "徐州云龙三中",
    "asylumaddress": "江苏省徐州市云龙区民祥园路 附近",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "云龙区",
    "managementunithead": "李光",
    "createtime": "1949",
    "ischeck": "是",
    "longitude": 117.276176,
    "latitude": 34.222487
},
{
    "cityname": "徐州",
    "asylumname": "徐州第三十六中学",
    "asylumaddress": "徐州市翟山新村",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": null,
    "createtime": "1975",
    "ischeck": "是",
    "longitude": 117.208242,
    "latitude": 34.218783
},
{
    "cityname": "徐州",
    "asylumname": "徐州开发区中学",
    "asylumaddress": "杨山路7号",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "开发区",
    "managementunithead": "刘同富",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 117.259475,
    "latitude": 34.29261
},
{
    "cityname": "徐州",
    "asylumname": "徐州经济技术开发区工业学校",
    "asylumaddress": "徐州经济开发区大庙镇",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "开发区",
    "managementunithead": "刘同富",
    "createtime": "1985",
    "ischeck": "是",
    "longitude": 117.285729,
    "latitude": 34.272584
},
{
    "cityname": "徐州",
    "asylumname": "徐州师范大学云龙校区",
    "asylumaddress": "云龙区和平路57",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 3.7,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "云龙区",
    "managementunithead": "李光",
    "createtime": "1952",
    "ischeck": "是",
    "longitude": 117.199642,
    "latitude": 34.254208
},
{
    "cityname": "徐州",
    "asylumname": "徐州工程学院（新城区校区）",
    "asylumaddress": "徐州工程学院富春路",
    "placelevel": "固定型",
    "placetype": "场地型",
    "asylumarea": 14.2,
    "asylumnumber": 7.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "新城区",
    "managementunithead": "陈沛",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 117.305962,
    latitude: 34.199299
},
{
    "cityname": "徐州",
    "asylumname": "徐州滨湖公园",
    "asylumaddress": "江苏省徐州市泉山区湖中路",
    "placelevel": "中心型",
    "placetype": "场地型",
    "asylumarea": 48.5,
    "asylumnumber": 24.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "2001",
    "ischeck": "是",
    "longitude": 117.153803,
    "latitude": 34.252882
},
{
    "cityname": "徐州",
    "asylumname": "徐州淮塔及周边",
    "asylumaddress": "江苏省徐州市泉山区解放路",
    "placelevel": "中心型",
    "placetype": "场地型",
    "asylumarea": 59.4,
    "asylumnumber": 29.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "泉山区",
    "managementunithead": "苏海军",
    "createtime": "2007",
    "ischeck": "是",
    "longitude": 117.188107,
    "latitude": 34.271553
},
{
    "cityname": "徐州",
    "asylumname": "徐州大龙湖公园周边及新城区市民广场",
    "asylumaddress": "江苏省徐州市云龙区环湖路",
    "placelevel": "中心型",
    "placetype": "场地型",
    "asylumarea": 52,
    "asylumnumber": 26,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "不全",
    "comprebasicfacilitymatch": "不全",
    "managementunit": "云龙区",
    "managementunithead": "李光",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 117.29062,
    "latitude": 34.208814
},
{
    "cityname": "常州",
    "asylumname": "常州淹城公园",
    "asylumaddress": "武进区",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 44.8,
    "asylumnumber": 22.4,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": "2014.01",
    "ischeck": "否",
    "longitude": 119.944897,
    "latitude": 31.713271
},
{
    "cityname": "常州",
    "asylumname": "常州新天地公园",
    "asylumaddress": "武进区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 10.2,
    "asylumnumber": 5.1,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.964028,
    "latitude": 31.716173
},
{
    "cityname": "常州",
    "asylumname": "常州科教城绿地",
    "asylumaddress": "武进区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 15.6,
    "asylumnumber": 7.8,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.966196,
    "latitude": 31.689062
},
{
    "cityname": "常州",
    "asylumname": "常州三勤农业生态园",
    "asylumaddress": "武进区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 7.8,
    "asylumnumber": 3.9,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 120.001438,
    "latitude": 31.686548
},
{
    "cityname": "常州",
    "asylumname": "常州揽月湾绿地",
    "asylumaddress": "武进区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.6,
    "asylumnumber": 2.3,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.852376,
    "latitude": 31.691354
},
{
    "cityname": "常州",
    "asylumname": "常州紫薇园",
    "asylumaddress": "武进区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 1,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.804263,
    "latitude": 31.703323
},
{
    "cityname": "常州",
    "asylumname": "常州丁塘河湿地公园",
    "asylumaddress": "戚墅堰区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 12.1,
    "asylumnumber": 6.1,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "铁建处",
    "managementunithead": null,
    "createtime": "2014.05",
    "ischeck": "否",
    "longitude": 120.0402,
    "latitude": 31.763924
},
{
    "cityname": "常州",
    "asylumname": "常州圩墩公园",
    "asylumaddress": "戚墅堰区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.9,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "圩墩公园管理处",
    "managementunithead": "张建刚",
    "createtime": "2009.1",
    "ischeck": "否",
    "longitude": 119.981861,
    "latitude": 31.771397
},
{
    "cityname": "常州",
    "asylumname": "常州飞龙公园",
    "asylumaddress": "天宁区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.945596,
    "latitude": 31.817813
},
{
    "cityname": "常州",
    "asylumname": "常州火车站北广场",
    "asylumaddress": "天宁区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.9,
    "asylumnumber": 2,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.981861,
    "latitude": 31.771397
},
{
    "cityname": "常州",
    "asylumname": "常州紫荆公园",
    "asylumaddress": "天宁区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8.1,
    "asylumnumber": 4.1,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "绿化管理指导站",
    "managementunithead": "陈继峰",
    "createtime": "2010.4",
    "ischeck": "否",
    "longitude": 120.012265,
    "latitude": 31.797548
},
{
    "cityname": "常州",
    "asylumname": "常州荆川公园",
    "asylumaddress": "钟楼区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8.2,
    "asylumnumber": 4.1,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "荆川公园管理处",
    "managementunithead": "顾军",
    "createtime": "2005.9",
    "ischeck": "否",
    "longitude": 119.928025,
    "latitude": 31.772098
},
{
    "cityname": "常州",
    "asylumname": "常州兰园",
    "asylumaddress": "钟楼区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.6,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "区",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.953651,
    "latitude": 31.774012
},
{
    "cityname": "常州",
    "asylumname": "常州蔷薇园",
    "asylumaddress": "钟楼区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.2,
    "asylumnumber": 2.1,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "绿化管理指导站",
    "managementunithead": "陈继峰",
    "createtime": "2007.1",
    "ischeck": "否",
    "longitude": 119.992008,
    "latitude": 31.755631
},
{
    "cityname": "常州",
    "asylumname": "常州五星公园",
    "asylumaddress": "钟楼区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 7.9,
    "asylumnumber": 4,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "绿化管理指导站",
    "managementunithead": "陈继峰",
    "createtime": "2010.9",
    "ischeck": "否",
    "longitude": 119.927884,
    "latitude": 31.789496
},
{
    "cityname": "常州",
    "asylumname": "常州荷园",
    "asylumaddress": "钟楼区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 6.7,
    "asylumnumber": 3.4,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "绿化管理指导站",
    "managementunithead": "陈继峰",
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.912841,
    "latitude": 31.829771
},
{
    "cityname": "常州",
    "asylumname": "常州西林公园",
    "asylumaddress": "钟楼区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 7.5,
    "asylumnumber": 3.8,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "绿化管理指导站",
    "managementunithead": "陈继峰",
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.886417,
    "latitude": 31.775978
},
{
    "cityname": "常州",
    "asylumname": "常州薛家公园",
    "asylumaddress": "新北区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "新北区",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.950934,
    "latitude": 31.729527
},
{
    "cityname": "常州",
    "asylumname": "常州新区公园",
    "asylumaddress": "新北区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5.6,
    "asylumnumber": 2.8,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "新北区",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.989768,
    "latitude": 31.832986
},
{
    "cityname": "常州",
    "asylumname": "常州市民广场",
    "asylumaddress": "新北区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 9.4,
    "asylumnumber": 4.7,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "绿化管理指导站",
    "managementunithead": "陈继峰",
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.980652,
    "latitude": 31.81403
},
{
    "cityname": "常州",
    "asylumname": "常州恐龙园",
    "asylumaddress": "新北区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 11.4,
    "asylumnumber": 5.7,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "基本齐全",
    "managementunit": "新北区",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 119.981861,
    "latitude": 31.771397
},
{
    "cityname": "市区",
    "asylumname": "姑苏区桐泾公园",
    "asylumaddress": "姑苏区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 13,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 120.607553,
    "latitude": 31.288301
},
{
    "cityname": "市区",
    "asylumname": "姑苏区桂花公园",
    "asylumaddress": "姑苏区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 12,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.650227,
    "latitude": 31.298119
},
{
    "cityname": "市区",
    "asylumname": "姑苏区苏州公园",
    "asylumaddress": "姑苏区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.634189,
    "latitude": 31.31101
},
{
    "cityname": "市区",
    "asylumname": "姑苏区白杨湾湿地公园",
    "asylumaddress": "姑苏区",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 25,
    "asylumnumber": 5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.614279,
    "latitude": 31.32643
},
{
    "cityname": "市区",
    "asylumname": "姑苏区虎丘湿地公园",
    "asylumaddress": "姑苏区",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 80,
    "asylumnumber": 20,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.614279,
    "latitude": 31.32643
},
{
    "cityname": "市区",
    "asylumname": "姑苏区东汇公园",
    "asylumaddress": "姑苏区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.639064,
    "latitude": 31.337805
},
{
    "cityname": "市区",
    "asylumname": "姑苏区官渡里立交景观绿地",
    "asylumaddress": "姑苏区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 9,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.614279,
    "latitude": 31.32643
},
{
    "cityname": "市区",
    "asylumname": "姑苏区东园",
    "asylumaddress": "姑苏区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.643914,
    "latitude": 31.324923
},
{
    "cityname": "市区",
    "asylumname": "平江新城体育公园",
    "asylumaddress": "姑苏区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.62525,
    "latitude": 31.350766
},
{
    "cityname": "市区",
    "asylumname": "高新区玉山公园",
    "asylumaddress": "高新区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 7,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 120.554788,
    "latitude": 31.290498
},
{
    "cityname": "市区",
    "asylumname": "工业园区南施公园",
    "asylumaddress": "工业园区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 0.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.741285,
    "latitude": 31.318016
},
{
    "cityname": "市区",
    "asylumname": "相城区活力岛广场",
    "asylumaddress": "相城区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 7,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.612851,
    "latitude": 31.375202
},
{
    "cityname": "常熟",
    "asylumname": "常熟虞山公园应急避难场所",
    "asylumaddress": "虞山北路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 9,
    "asylumnumber": 4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "虞山公园",
    "managementunithead": "公园负责人",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.717774,
    "latitude": 31.66987
},
{
    "cityname": "常熟",
    "asylumname": "常熟服装城文化广场避难场所",
    "asylumaddress": "服装城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "服装城",
    "managementunithead": "王利康",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.750267,
    "latitude": 31.628855
},
{
    "cityname": "太仓",
    "asylumname": "太仓市金仓湖地震应急避难场所",
    "asylumaddress": "太沙路与新港路交界处金仓湖公园内",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 56,
    "asylumnumber": 10,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "太仓市金仓湖旅游发展有限公司",
    "managementunithead": "王翠霞",
    "createtime": null,
    "ischeck": "是",
    "longitude": 121.108925,
    "latitude": 31.519242
},
{
    "cityname": "吴江",
    "asylumname": "吴江公园地震应急避难场所",
    "asylumaddress": "吴江公园",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 20,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "吴江园林局",
    "managementunithead": "谭融",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.639036,
    "latitude": 31.162838
},
{
    "cityname": "张家港",
    "asylumname": "张家港梁丰生态园",
    "asylumaddress": "沙洲东路",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 46,
    "asylumnumber": 23,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公园管理处",
    "managementunithead": "曹淑芬",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.579224,
    "latitude": 31.860181
},
{
    "cityname": "张家港",
    "asylumname": "张家港暨阳湖",
    "asylumaddress": "金港",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 86,
    "asylumnumber": 40,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "暨阳湖开发公司",
    "managementunithead": "张满兴",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.545729,
    "latitude": 31.85955
},
{
    "cityname": "昆山",
    "asylumname": "昆山滨江公园",
    "asylumaddress": "昆泰路",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 9,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "绿化管理所",
    "managementunithead": "费建东",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.974163,
    "latitude": 31.396991
},
{
    "cityname": "市区",
    "asylumname": "崇川区滨江公园-园博园",
    "asylumaddress": "崇川区",
    "placelevel": "中心",
    "placetype": "场地型、建筑型",
    "asylumarea": 48.4,
    "asylumnumber": 20,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "滨江公园-园博园管理处",
    "managementunithead": "陈  林",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.87988,
    "latitude": 31.961099
},
{
    "cityname": "市区",
    "asylumname": "港闸区古港花都",
    "asylumaddress": "港闸区",
    "placelevel": "中心",
    "placetype": "场地型、建筑型",
    "asylumarea": 10,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市绿化处",
    "managementunithead": "夏兴峰",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.789805,
    "latitude": 32.035748
},
{
    "cityname": "市区",
    "asylumname": "崇川区文峰公园",
    "asylumaddress": "崇川区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 3.9,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市绿化处",
    "managementunithead": "夏兴峰",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.882204,
    "latitude": 32.015227
},
{
    "cityname": "市区",
    "asylumname": "崇川区体育公园",
    "asylumaddress": "崇川区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 4.3,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市绿化处",
    "managementunithead": "夏兴峰",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.870271,
    "latitude": 32.012
},
{
    "cityname": "市区",
    "asylumname": "崇川区濠东绿地",
    "asylumaddress": "崇川区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 6.9,
    "asylumnumber": 3.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "濠河管理处",
    "managementunithead": "邹亚萍",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.887599,
    "latitude": 31.962661
},
{
    "cityname": "市区",
    "asylumname": "崇川区环西文化广场",
    "asylumaddress": "崇川区　",
    "placelevel": "固定　",
    "placetype": "场地　",
    "asylumarea": 2.5,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "濠河管理处",
    "managementunithead": "邹亚萍",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.867708,
    "latitude": 32.020632
},
{
    "cityname": "市区",
    "asylumname": "崇川区环保公园",
    "asylumaddress": "崇川区　",
    "placelevel": "固定　",
    "placetype": "场地　",
    "asylumarea": 13,
    "asylumnumber": 5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "绿化处",
    "managementunithead": "夏兴峰",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.946915,
    "latitude": 31.991259
},
{
    "cityname": "市区",
    "asylumname": "崇川区高等商贸学校体育场（馆)",
    "asylumaddress": "崇川区",
    "placelevel": "固定",
    "placetype": "场地型、建筑型",
    "asylumarea": 3.5,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "高技院",
    "managementunithead": "费晓梅",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 120.887599,
    "latitude": 31.962661
},
{
    "cityname": "市区",
    "asylumname": "港闸区华强文化科技实践基地　",
    "asylumaddress": "港闸区　",
    "placelevel": "固定",
    "placetype": "建筑、场地型",
    "asylumarea": 3.4,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "华强城",
    "managementunithead": "高  健",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 120.823875,
    "latitude": 32.071256
},
{
    "cityname": "通州区",
    "asylumname": "通州区南山湖公园",
    "asylumaddress": "城东新区",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 20,
    "asylumnumber": 7.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "区园林绿化中心",
    "managementunithead": "吴尢宏",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 121.094315,
    "latitude": 32.063629
},
{
    "cityname": "通州区",
    "asylumname": "通州区人民公园",
    "asylumaddress": "老城区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "区园林绿化中心",
    "managementunithead": "吴尢宏",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 121.077094,
    "latitude": 32.087736
},
{
    "cityname": "启东",
    "asylumname": "启东新城区市民广场",
    "asylumaddress": "世纪大道北侧、公园南路东侧",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 33,
    "asylumnumber": 15,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林绿化管理处",
    "managementunithead": "陈娟娟",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 121.66069,
    "latitude": 31.796908
},
{
    "cityname": "启东",
    "asylumname": "启东人民公园",
    "asylumaddress": "人民路、公园路口",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 1.3,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "园林绿化管理处",
    "managementunithead": "陈娟娟",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 121.678822,
    "latitude": 31.871302
},
{
    "cityname": "启东",
    "asylumname": "启东紫薇公园",
    "asylumaddress": "公园北路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 11,
    "asylumnumber": 5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "园林绿化管理处",
    "managementunithead": "陈娟娟",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 121.680686,
    "latitude": 31.824799
},
{
    "cityname": "启东",
    "asylumname": "启东儿童乐园",
    "asylumaddress": "头兴港河东侧（人民路—紫薇路）",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 9.5,
    "asylumnumber": 4.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": " 选配",
    "comprebasicfacilitymatch": " 选配",
    "managementunit": "园林绿化管理处",
    "managementunithead": "陈娟娟",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 121.654562,
    "latitude": 31.820781
},
{
    "cityname": "启东",
    "asylumname": "启东广电广场",
    "asylumaddress": "头兴港河西侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 3.3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "园林绿化管理处",
    "managementunithead": "陈娟娟",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 121.65416,
    "latitude": 31.823716
},
{
    "cityname": "启东",
    "asylumname": "启东庙港河",
    "asylumaddress": "长龙东路—纬二路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 13,
    "asylumnumber": 6.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "园林绿化管理处",
    "managementunithead": "陈娟娟",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 121.678822,
    "latitude": 31.871302
},
{
    "cityname": "海门市",
    "asylumname": "海门市东洲公园",
    "asylumaddress": "人民路北、育才路东",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "验收",
    "longitude": 121.18616,
    "latitude": 31.8983
},
{
    "cityname": "海门市",
    "asylumname": "海门市滨河广场",
    "asylumaddress": "解放西路北、新海路南",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 121.16859,
    "latitude": 31.901148
},
{
    "cityname": "海门市",
    "asylumname": "海门市文化广场",
    "asylumaddress": "人民路南、瑞江路西",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 121.202418,
    "latitude": 31.901671
},
{
    "cityname": "海门市",
    "asylumname": "海门市映翠园",
    "asylumaddress": "黄海路北、越秀路东",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 121.188742,
    "latitude": 31.891447
},
{
    "cityname": "海门市",
    "asylumname": "海门市江海风情园",
    "asylumaddress": "大生路南",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8.3,
    "asylumnumber": 4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 121.174345,
    "latitude": 31.831304
},
{
    "cityname": "海门市",
    "asylumname": "海门市听月湾",
    "asylumaddress": "丝绸路南",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "市人防",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 121.31247,
    "latitude": 31.956039
},
{
    "cityname": "如东",
    "asylumname": "如东人民公园",
    "asylumaddress": "日晖西路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "否",
    "managementunit": "住建局",
    "managementunithead": "沈袁军",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 121.185858,
    "latitude": 32.314472
},
{
    "cityname": "如东",
    "asylumname": "如东湿地",
    "asylumaddress": "双甸镇",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "否",
    "comprebasicfacilitymatch": "否",
    "managementunit": "双甸镇",
    "managementunithead": "时彬",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 121.059244,
    "latitude": 32.387662
},
{
    "cityname": "如皋",
    "asylumname": "如皋安定广场",
    "asylumaddress": "如城",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市土管局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.571529,
    "latitude": 32.392049
},
{
    "cityname": "如皋",
    "asylumname": "如皋水绘园",
    "asylumaddress": "如城",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 5.3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "水绘园发展公司",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.575807,
    "latitude": 32.401678
},
{
    "cityname": "如皋",
    "asylumname": "如皋行政服务中心前绿地",
    "asylumaddress": "如城",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 4.2,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市机关事务局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.568009,
    "latitude": 32.381685
},
{
    "cityname": "如皋",
    "asylumname": "如皋文化广场",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.2,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林处",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "已建",
    "longitude": 120.578726,
    "latitude": 32.373863
},
{
    "cityname": "如皋",
    "asylumname": "如皋奥林匹克公园",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 9.3,
    "asylumnumber": 2.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市体育局",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "已建",
    "longitude": 120.595512,
    "latitude": 32.379129
},
{
    "cityname": "如皋",
    "asylumname": "如皋实验初中操场",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市实验初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.557898,
    "latitude": 32.37663
},
{
    "cityname": "如皋",
    "asylumname": "如皋如皋体育场",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.591282,
    "latitude": 32.392074
},
{
    "cityname": "如皋",
    "asylumname": "如皋红十四军公园",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市民政局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.598503,
    "latitude": 32.392432
},
{
    "cityname": "如皋",
    "asylumname": "如皋海阳南路绿化带",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "选配",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林处",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.597314,
    "latitude": 32.356719
},
{
    "cityname": "如皋",
    "asylumname": "如皋新汽车站停车场",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "汽运集团",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "已建",
    "longitude": 120.564097,
    "latitude": 32.366571
},
{
    "cityname": "如皋",
    "asylumname": "如皋瓜果市场地块",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.3,
    "basicfacilitymatch": "选配",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林处",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "已建",
    "longitude": 120.580144,
    "latitude": 32.273616
},
{
    "cityname": "如皋",
    "asylumname": "如皋东方大寿星园",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 12.6,
    "asylumnumber": 3.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "寿星园公司",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.556553,
    "latitude": 32.370014
},
{
    "cityname": "如皋",
    "asylumname": "如皋花市路绿化",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 1,
    "basicfacilitymatch": "选配",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "已建",
    "longitude": 120.539396,
    "latitude": 32.411526
},
{
    "cityname": "如皋",
    "asylumname": "如皋海阳南路绿化",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 6.8,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "选配",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": "已建",
    "longitude": 120.597314,
    "latitude": 32.356719
},
{
    "cityname": "如皋",
    "asylumname": "如皋紫光路绿化",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "选配",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林处",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "已建",
    "longitude": 120.580144,
    "latitude": 32.273616
},
{
    "cityname": "如皋",
    "asylumname": "如皋滨溪路绿地",
    "asylumaddress": "如城",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林处",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "已建",
    "longitude": 120.561173,
    "latitude": 32.398867
},
{
    "cityname": "海安",
    "asylumname": "海安七星湖公园及广场",
    "asylumaddress": "海安",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 20,
    "asylumnumber": 12,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "开发区",
    "managementunithead": "张书伟",
    "createtime": "2008",
    "ischeck": "是",
    "longitude": 120.473927,
    "latitude": 32.553985
},
{
    "cityname": "海安",
    "asylumname": "海安市民广场（海陵公园）",
    "asylumaddress": "海安",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 12.5,
    "asylumnumber": 4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "建设局",
    "managementunithead": "周道",
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 120.464301,
    "latitude": 32.547907
},
{
    "cityname": "海安",
    "asylumname": "海安行政中心大楼、时代广场",
    "asylumaddress": "海安",
    "placelevel": "中心",
    "placetype": "建筑型、场地型",
    "asylumarea": 8.9,
    "asylumnumber": 3.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "机关事务管理局",
    "managementunithead": "张顺",
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 120.473927,
    "latitude": 32.553985
},
{
    "cityname": "海安",
    "asylumname": "海安人民广场",
    "asylumaddress": "海安",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.5,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "建设局",
    "managementunithead": "周道",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.47968,
    "latitude": 32.527687
},
{
    "cityname": "海安",
    "asylumname": "海安老通扬河景观带",
    "asylumaddress": "海安",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 20,
    "asylumnumber": 6.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "建设局",
    "managementunithead": "周道",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.473927,
    "latitude": 32.553985
},
{
    "cityname": "海安",
    "asylumname": "海安职教中心",
    "asylumaddress": "海安　",
    "placelevel": "固定　",
    "placetype": "建筑型、场地型　",
    "asylumarea": 4,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "职教园区",
    "managementunithead": "王君",
    "createtime": "2012",
    "ischeck": "是　",
    "longitude": 120.461229,
    "latitude": 32.531978
},
{
    "cityname": "海安　",
    "asylumname": "海安苏中植物园",
    "asylumaddress": "海安　",
    "placelevel": "固定　",
    "placetype": "场地型",
    "asylumarea": 9.9,
    "asylumnumber": 3.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "选配",
    "comprebasicfacilitymatch": "选配",
    "managementunit": "城投公司",
    "managementunithead": "周道",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 120.471072,
    "latitude": 32.565253
},
{
    "cityname": "市区",
    "asylumname": "苍梧路南侧苍梧绿园",
    "asylumaddress": "苍梧路南侧",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 13.2,
    "asylumnumber": 4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "苍梧绿园管理处",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 119.203341,
    "latitude": 34.602807
},
{
    "cityname": "市区",
    "asylumname": "海连路北侧新浦公园",
    "asylumaddress": "海连路北侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 6,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "新浦区住建局",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 119.180622,
    "latitude": 34.604108
},
{
    "cityname": "灌南",
    "asylumname": "灌南英雄广场",
    "asylumaddress": "人民东路北侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.9,
    "asylumnumber": 2.4,
    "basicfacilitymatch": "无",
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": "无",
    "managementunit": "灌南县住建局",
    "managementunithead": "刘化",
    "createtime": "2010.3",
    "ischeck": "验收",
    "longitude": 119.367959,
    "latitude": 34.101638
},
{
    "cityname": "灌南",
    "asylumname": "灌南县高级中学",
    "asylumaddress": "人民西路南侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 12.5,
    "asylumnumber": 6.2,
    "basicfacilitymatch": "无",
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": "无",
    "managementunit": "灌南县住建局",
    "managementunithead": "刘化",
    "createtime": "2011.5",
    "ischeck": "验收",
    "longitude": 119.328765,
    "latitude": 34.091929
},
{
    "cityname": "灌南",
    "asylumname": "灌南县第二中学",
    "asylumaddress": "新东路西侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.1,
    "asylumnumber": 1,
    "basicfacilitymatch": "无",
    "commonfacilitymatch": "有",
    "comprebasicfacilitymatch": "无",
    "managementunit": "灌南县住建局",
    "managementunithead": "刘化",
    "createtime": "2012.5",
    "ischeck": "验收",
    "longitude": 119.360342,
    "latitude": 34.103965
},
{
    "cityname": "东海",
    "asylumname": "东海西双湖风景区",
    "asylumaddress": "晶都大道南侧",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 52.8,
    "asylumnumber": 17.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "西双湖风景管理处",
    "managementunithead": "杨智",
    "createtime": "2008",
    "ischeck": "是",
    "longitude": 118.730176,
    "latitude": 34.5346
},
{
    "cityname": "东海",
    "asylumname": "东海体育馆",
    "asylumaddress": "富华路东侧",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 7.1,
    "asylumnumber": 2.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "东海县体育局",
    "managementunithead": "戚海建",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 118.75736,
    "latitude": 34.544484
},
{
    "cityname": "东海",
    "asylumname": "东海水晶公园",
    "asylumaddress": "牛山路东侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 11.2,
    "asylumnumber": 3.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "东海县政府",
    "managementunithead": "李利",
    "createtime": "2008",
    "ischeck": "是",
    "longitude": 121.501137,
    "latitude": 31.258972
},
{
    "cityname": "东海",
    "asylumname": "东海市政广场",
    "asylumaddress": "县政府前",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.8,
    "asylumnumber": 1.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "东海县政府",
    "managementunithead": "李利",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 118.79231,
    "latitude": 34.556383
},
{
    "cityname": "东海",
    "asylumname": "东海金牛公园",
    "asylumaddress": "利民路北侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 2.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "东海县政府",
    "managementunithead": "李利",
    "createtime": "2006",
    "ischeck": "是",
    "longitude": 118.761435,
    "latitude": 34.532041
},
{
    "cityname": "东海",
    "asylumname": "东海高级中学",
    "asylumaddress": "幸福北路东侧",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 13,
    "asylumnumber": 5.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "东海县教育局",
    "managementunithead": "蒋焱",
    "createtime": "2000",
    "ischeck": "是",
    "longitude": 118.79231,
    "latitude": 34.556383
},
{
    "cityname": "东海",
    "asylumname": "东海人民广场",
    "asylumaddress": "幸福路西",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "东海县国土局",
    "managementunithead": "辛华",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 118.76687,
    "latitude": 34.530347
},
{
    "cityname": "赣榆",
    "asylumname": "赣榆青口生态公园",
    "asylumaddress": "黄海西路南侧",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 16.1,
    "asylumnumber": 8.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "青口生态公园管理处",
    "managementunithead": "陈祥琴",
    "createtime": "2010",
    "ischeck": null,
    "longitude": 119.114662,
    "latitude": 34.845004
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市钵池山公园",
    "asylumaddress": "清河区",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 58.5,
    "asylumnumber": 29,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "钵池山公园",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 119.072942,
    "latitude": 33.603914
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市古黄河生态民俗园",
    "asylumaddress": "清河区",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 44.2,
    "asylumnumber": 22,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "古黄河生态民俗园",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": null,
    "longitude": 118.999136,
    "latitude": 33.611749
},
{
    "cityname": "淮安市",
    "asylumname": "淮阴区迎宾广场绿地",
    "asylumaddress": "承德路西侧、长江东路北侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 8.9,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "淮阴区政府",
    "managementunithead": null,
    "createtime": "2014",
    "ischeck": null,
    "longitude": 118.935664,
    "latitude": 33.664059
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市荷花公园",
    "asylumaddress": "黄河路北侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 4.8,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "淮阴区住建局",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 119.021024,
    "latitude": 33.629039
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市盐河公园",
    "asylumaddress": "南昌路西侧、盐河南岸 ",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 17.6,
    "asylumnumber": 6.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "淮阴区住建局",
    "managementunithead": null,
    "createtime": "2014",
    "ischeck": null,
    "longitude": 119.019662,
    "latitude": 33.638424
},
{
    "cityname": "　淮安市",
    "asylumname": "淮安市火车站中心广场及汽车客运北站",
    "asylumaddress": "淮海北路与珠江路交叉处",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 12,
    "asylumnumber": 4.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "　淮阴区交通局",
    "managementunithead": null,
    "createtime": "2014",
    "ischeck": null,
    "longitude": 119.030186,
    "latitude": 33.606513
},
{
    "cityname": "　淮安市",
    "asylumname": "淮阴工学院校区运动场",
    "asylumaddress": "北京北路西侧、大治路南侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 7.1,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "淮阴工学院　",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 119.041625,
    "latitude": 33.55845
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市清江中学校区、市体育馆",
    "asylumaddress": "淮海北路西侧、昌盛路北",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 3.6,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "清江中学、市体育馆",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": null,
    "longitude": 119.027857,
    "latitude": 33.607441
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市大运河广场",
    "asylumaddress": "漕运东路北、丁香路南侧 ",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 5.4,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "淮安市园林局",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": null,
    "longitude": 119.041299,
    "latitude": 33.602423
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市开发区合肥路游园",
    "asylumaddress": "海口路南侧、合肥路西侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 7,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "开发区管委会",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": null,
    "longitude": 119.092481,
    "latitude": 33.590653
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市城南体育场",
    "asylumaddress": "解放西路南、港口路东侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "清浦区政府",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": null,
    "longitude": 119.033195,
    "latitude": 33.584066
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市体育中心",
    "asylumaddress": "新城枚皋路北侧、通甫路东侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 31.6,
    "asylumnumber": 13,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "淮安市体育局",
    "managementunithead": null,
    "createtime": "2014",
    "ischeck": null,
    "longitude": 119.030186,
    "latitude": 33.606513
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市漕运广场",
    "asylumaddress": "楚州区镇淮楼北",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 4.2,
    "asylumnumber": 2,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "淮安区旅游局",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": null,
    "longitude": 119.151531,
    "latitude": 33.514956
},
{
    "cityname": "淮安市",
    "asylumname": "淮安市楚州广场",
    "asylumaddress": "楚州区楚州大道、纬三路南侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 8.7,
    "asylumnumber": 3,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "淮安区住建局",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": null,
    "longitude": 119.303107,
    "latitude": 33.504018
},
{
    "cityname": "淮安市",
    "asylumname": "淮安四馆",
    "asylumaddress": "翔宇大道以东、枚皋路以北",
    "placelevel": "固定",
    "placetype": "建筑",
    "asylumarea": 8.6,
    "asylumnumber": 4.3,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "淮安新区管委会",
    "managementunithead": null,
    "createtime": "2014",
    "ischeck": null,
    "longitude": 119.313295,
    "latitude": 33.528349
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴西郊风景区",
    "asylumaddress": "延陵路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 13,
    "asylumnumber": 3.2,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "泰兴市民防局",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": null,
    "longitude": 120.135346,
    "latitude": 32.213679
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴公园",
    "asylumaddress": "济川北路",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 22.5,
    "asylumnumber": 3.5,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "泰兴园林处",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 120.036485,
    "latitude": 32.185893
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴中学",
    "asylumaddress": "鼓楼中路",
    "placelevel": "固定",
    "placetype": "场地、建筑",
    "asylumarea": 8.6,
    "asylumnumber": 2.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "泰兴中学",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 104.241169,
    "latitude": 30.788155
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴市第一高中",
    "asylumaddress": "江平路",
    "placelevel": "固定",
    "placetype": "场地、建筑",
    "asylumarea": 6,
    "asylumnumber": 1.5,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市第一高中",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 120.135346,
    "latitude": 32.213679
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴襟江小学",
    "asylumaddress": "羌溪路",
    "placelevel": "固定",
    "placetype": "场地、建筑",
    "asylumarea": 6.5,
    "asylumnumber": 1.6,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "襟江小学",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 120.038358,
    "latitude": 32.16678
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴鼓楼广场",
    "asylumaddress": "国庆中路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 1.2,
    "asylumnumber": 0.3,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "泰兴市民防局",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": null,
    "longitude": 120.027776,
    "latitude": 32.172842
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴外国语学校",
    "asylumaddress": "东润路北",
    "placelevel": "固定",
    "placetype": "场地、建筑",
    "asylumarea": 4.6,
    "asylumnumber": 1.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "外国语学校",
    "managementunithead": null,
    "createtime": "2009",
    "ischeck": null,
    "longitude": 120.135346,
    "latitude": 32.213679
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴银杏公园",
    "asylumaddress": "国庆东路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 5.2,
    "asylumnumber": 1.3,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "泰兴园林处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": null,
    "longitude": 120.0586,
    "latitude": 32.176105
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴市体育中心",
    "asylumaddress": "文昌西路",
    "placelevel": "固定",
    "placetype": "场地、建筑",
    "asylumarea": 15,
    "asylumnumber": 3.7,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "市体育中心",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": null,
    "longitude": 120.135346,
    "latitude": 32.213679
},
{
    "cityname": "泰兴",
    "asylumname": "泰兴风光带",
    "asylumaddress": "如泰运河",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 4.7,
    "asylumnumber": 1.2,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "泰兴园林处",
    "managementunithead": null,
    "createtime": "2012",
    "ischeck": null,
    "longitude": 120.135346,
    "latitude": 32.213679
},
{
    "cityname": "宿迁",
    "asylumname": "宿迁古黄河水景公园",
    "asylumaddress": "宿城区骆马湖路北",
    "placelevel": "固定避难场所",
    "placetype": "场地型",
    "asylumarea": 6.8,
    "asylumnumber": 3.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市风景名胜区管理处",
    "managementunithead": "高维芹",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 118.277654,
    "latitude": 33.972943
},
{
    "cityname": "宿迁",
    "asylumname": "宿迁市城市防空疏散中心暨地震应急避难场所",
    "asylumaddress": "宿城新区树人国际学校北侧古黄河风光带内",
    "placelevel": "中心避难场所",
    "placetype": "场地型",
    "asylumarea": 7.7,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市民防局",
    "managementunithead": "张绪金",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 118.296893,
    "latitude": 33.95205
},
{
    "cityname": "宿迁",
    "asylumname": "宿迁九鼎公园地震应急避难场所",
    "asylumaddress": "宿城区人民大道西，威海路南",
    "placelevel": "固定避难场所",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "九鼎公园管理办公室",
    "managementunithead": "李先伟",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 118.277868,
    "latitude": 33.94612
},
{
    "cityname": "宿迁",
    "asylumname": "千宿迁鸟园地震应急避难场所",
    "asylumaddress": "宿豫区政府对面",
    "placelevel": "固定避难场所",
    "placetype": "场地型",
    "asylumarea": 9.7,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "千鸟园广场管理办公室",
    "managementunithead": "刘主席",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 118.296893,
    "latitude": 33.95205
},
{
    "cityname": "宿迁",
    "asylumname": "宿迁雄壮河湾景区地震应急避难场所",
    "asylumaddress": "宿城区发展大道东，黄河二号桥西",
    "placelevel": "固定避难场所",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市风景名胜区管理处",
    "managementunithead": "高维芹",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 118.296893,
    "latitude": 33.95205
},
{
    "cityname": "宿迁",
    "asylumname": "宿迁文昌中学操场",
    "asylumaddress": "宿豫区江山大道东，泰山路南",
    "placelevel": "固定避难场所",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "宿豫区教育局",
    "managementunithead": "朱主任",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 118.296893,
    "latitude": 33.95205
},
{
    "cityname": "宿迁",
    "asylumname": "宿迁宿豫中等专业学校操场",
    "asylumaddress": "宿豫区江山大道东，书山路南",
    "placelevel": "固定避难场所",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "宿豫区教育局",
    "managementunithead": "朱主任",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 118.338751,
    "latitude": 33.940054
},
{
    "cityname": "宿迁",
    "asylumname": "宿迁古黄河水景公园提升改造工程",
    "asylumaddress": "宿城区骆马湖路北",
    "placelevel": "固定避难场所",
    "placetype": "场地型",
    "asylumarea": 6.8,
    "asylumnumber": 3.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市风景名胜区管理处",
    "managementunithead": "高维芹",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 118.277654,
    "latitude": 33.972943
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪古徐广场",
    "asylumaddress": "长江路",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 4.8,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 118.222941,
    "latitude": 33.482915
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪民政广场",
    "asylumaddress": "团结河路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.2,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2005",
    "ischeck": "是",
    "longitude": 118.312551,
    "latitude": 33.425955
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪世纪公园",
    "asylumaddress": "青阳南路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2000",
    "ischeck": "是",
    "longitude": 118.227841,
    "latitude": 33.46923
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪濉河南岸",
    "asylumaddress": "顺河路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 7.8,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 118.384363,
    "latitude": 33.431974
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪汴河东岸",
    "asylumaddress": "汴河大桥-黄河大桥",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 6,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 118.247915,
    "latitude": 33.429872
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪城东森林公园",
    "asylumaddress": "泰山路、S245",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 30.8,
    "asylumnumber": 2.6,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 118.312551,
    "latitude": 33.425955
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪兴洪中学",
    "asylumaddress": "开发大道",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.3,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "1996",
    "ischeck": "是",
    "longitude": 118.196784,
    "latitude": 33.485831
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪第三中学",
    "asylumaddress": "长江路、青阳路",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.1,
    "asylumnumber": 0.3,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "1990",
    "ischeck": "是",
    "longitude": 118.233697,
    "latitude": 33.478156
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪汽车站旁绿地",
    "asylumaddress": "泗州西大街",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 118.194815,
    "latitude": 33.466873
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪江苏淮北中学",
    "asylumaddress": "山河路、嵩山路",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 2.4,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2004",
    "ischeck": "是",
    "longitude": 118.215906,
    "latitude": 33.459229
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪濉河南侧绿地",
    "asylumaddress": "青阳路、顺河路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.9,
    "asylumnumber": 1,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 118.384363,
    "latitude": 33.431974
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪泗洪县体育场",
    "asylumaddress": "山河路、人民路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2000",
    "ischeck": "是",
    "longitude": 118.220416,
    "latitude": 33.457418
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪育才实验中学",
    "asylumaddress": "山河路",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.4,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2003",
    "ischeck": "是",
    "longitude": 118.312551,
    "latitude": 33.425955
},
{
    "cityname": "泗洪",
    "asylumname": "泗洪城东水景公园",
    "asylumaddress": "古徐大道、黄山南路、团结河路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.9,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "泗洪住建局",
    "managementunithead": "赵毅",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 118.312551,
    "latitude": 33.425955
},
{
    "cityname": "泗阳县",
    "asylumname": "泗阳县市民广场",
    "asylumaddress": "北京路南侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 9,
    "asylumnumber": 5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林处",
    "managementunithead": "张成学",
    "createtime": "2012",
    "ischeck": "已验收",
    "longitude": 118.656941,
    "latitude": 33.708801
},
{
    "cityname": "泗阳县",
    "asylumname": "泗阳县森林公园",
    "asylumaddress": "运河大道东侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林处",
    "managementunithead": "张成学",
    "createtime": "2012",
    "ischeck": "已验收",
    "longitude": 118.707919,
    "latitude": 33.718822
},
{
    "cityname": "泗阳县",
    "asylumname": "泗阳县五里湖湿地公园",
    "asylumaddress": "淮海西路北侧",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林处",
    "managementunithead": "张成学",
    "createtime": "2012",
    "ischeck": "已验收",
    "longitude": 118.667625,
    "latitude": 33.726757
},
{
    "cityname": "泗阳县",
    "asylumname": "泗阳县生态公园",
    "asylumaddress": "文城路北侧，上海路西侧",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 10,
    "asylumnumber": 5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林处",
    "managementunithead": "张成学",
    "createtime": "2011",
    "ischeck": "已验收",
    "longitude": 118.656941,
    "latitude": 33.708801
},
{
    "cityname": "泗阳县",
    "asylumname": "泗阳县爱园公园",
    "asylumaddress": "爱园路西侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林处",
    "managementunithead": "张成学",
    "createtime": "2012",
    "ischeck": "已验收",
    "longitude": 118.687212,
    "latitude": 33.710307
},
{
    "cityname": "泗阳县",
    "asylumname": "泗阳县泗阳县体育中心",
    "asylumaddress": "上海路西侧、325省道南侧",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 9,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林处",
    "managementunithead": "张成学",
    "createtime": "2011",
    "ischeck": "已验收",
    "longitude": 118.719425,
    "latitude": 33.736319
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区市北高中",
    "asylumaddress": "市北高中",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 7.5,
    "asylumnumber": 3.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市北高中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.285502,
    "latitude": 31.627415
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区双河新村小学",
    "asylumaddress": "双河新村小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "双河新村小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区锡惠公园广场绿地",
    "asylumaddress": "锡惠公园广场绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "南长区",
    "asylumname": "南长区太湖广场北广场(与南禅寺单元共用)",
    "asylumaddress": "太湖广场北广场(与南禅寺单元共用)",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 6.4,
    "asylumnumber": 3.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "南长区政府",
    "managementunithead": null,
    "createtime": "2006.8",
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区规划小学（观山路与南湖大道交叉口北侧）（锡师太湖新城小学）",
    "asylumaddress": "规划小学（观山路与南湖大道交叉口北侧）（锡师太湖新城小学）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.4,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "锡师太湖新城小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.329677,
    "latitude": 31.501964
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区南泉中学（滨湖中学）",
    "asylumaddress": "南泉中学（滨湖中学）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.2,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "滨湖中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.272302,
    "latitude": 31.439092
},
{
    "cityname": "新区",
    "asylumname": "新区南星苑小学",
    "asylumaddress": "南星苑小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "南星苑小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.444902,
    "latitude": 31.478609
},
{
    "cityname": "江阴市",
    "asylumname": "江阴市体育中心（锡澄高速以东、拥军路以西、文化路以北、滨江路以南）",
    "asylumaddress": "体育中心（锡澄高速以东、拥军路以西、文化路以北、滨江路以南）",
    "placelevel": "中心",
    "placetype": "建筑型",
    "asylumarea": 31.3,
    "asylumnumber": 8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.41286,
    "latitude": 31.72783
},
{
    "cityname": "江阴市",
    "asylumname": "江阴市五星公园（文富路与五星路交叉口西北角）",
    "asylumaddress": "五星公园（文富路与五星路交叉口西北角）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.9,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.249373,
    "latitude": 31.912393
},
{
    "cityname": "南京",
    "asylumname": "南京尧化门避难场所",
    "asylumaddress": "南京尧家路100号",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": "配置齐全",
    "managementunit": "市民政局救灾处",
    "managementunithead": "王芳",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 118.899539,
    "latitude": 32.108963
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区三房小学",
    "asylumaddress": "三房小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.9,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "三房小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.533923,
    "latitude": 31.617255
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区查桥中学",
    "asylumaddress": "查桥中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.2,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "查桥中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.427473,
    "latitude": 31.607403
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区镇中公园",
    "asylumaddress": "镇中公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "洛社镇",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.195746,
    "latitude": 31.650205
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区梅梁中学",
    "asylumaddress": "梅梁中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "梅梁中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.122153,
    "latitude": 31.464086
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区无锡市一中",
    "asylumaddress": "无锡市一中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.3,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "无锡市一中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.288963,
    "latitude": 31.584736
},
{
    "cityname": "南长区",
    "asylumname": "南长区人民医院南侧绿地",
    "asylumaddress": "人民医院南侧绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.1,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "不齐全",
    "commonfacilitymatch": "不齐全",
    "comprebasicfacilitymatch": "不齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "淮安市",
    "asylumname": "淮阴区体育中心",
    "asylumaddress": "南昌路西侧、黄河东路北侧",
    "placelevel": "固定",
    "placetype": "建筑",
    "asylumarea": 2.8,
    "asylumnumber": 0.5,
    "basicfacilitymatch": null,
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "淮阴区体育局",
    "managementunithead": null,
    "createtime": "2014",
    "ischeck": null,
    "longitude": 118.935664,
    "latitude": 33.664059
},
{
    "cityname": "靖江",
    "asylumname": "靖江牧城生态园",
    "asylumaddress": "靖江市沿江高等级公路",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 6.2,
    "asylumnumber": 1.3,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "牧城公园管理处",
    "managementunithead": null,
    "createtime": "2014",
    "ischeck": "2014.6",
    "longitude": 120.276899,
    "latitude": 32.039443
},
{
    "cityname": "新区",
    "asylumname": "新区坊前学校",
    "asylumaddress": "坊前学校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "坊前学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.393657,
    "latitude": 31.564073
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区梁湖路街头绿地",
    "asylumaddress": "梁湖路街头绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.1,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.239191,
    "latitude": 31.555733
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区万科小学",
    "asylumaddress": "万科小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "万科小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.248502,
    "latitude": 31.466579
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区落霞实验小学（江南实验学校）",
    "asylumaddress": "落霞实验小学（江南实验学校）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "江南实验学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.458035,
    "latitude": 31.491368
},
{
    "cityname": "邳州市",
    "asylumname": "邳州市运河中学东校区操场",
    "asylumaddress": "老城区居住片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "运河中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.964973,
    "latitude": 34.344827
},
{
    "cityname": "盐城",
    "asylumname": "盐城盐渎公园",
    "asylumaddress": "人民南路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.5,
    "asylumnumber": 2.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市园林局",
    "managementunithead": "许国兴",
    "createtime": "2008",
    "ischeck": "验收",
    "longitude": 120.163891,
    "latitude": 33.370132
},
{
    "cityname": "盐城",
    "asylumname": "盐城人民公园",
    "asylumaddress": "黄海路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.2,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "市园林局",
    "managementunithead": "许国兴",
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 119.878551,
    "latitude": 33.293153
},
{
    "cityname": "盐城",
    "asylumname": "盐城新体育馆",
    "asylumaddress": "大庆路",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 4.7,
    "asylumnumber": 2.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "市体育局",
    "managementunithead": "杨玉成",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.142939,
    "latitude": 33.375238
},
{
    "cityname": "盐城",
    "asylumname": "盐城东亭湖公园",
    "asylumaddress": "文化中心",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.5,
    "asylumnumber": 1.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "亭湖建设局",
    "managementunithead": "朱超",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 120.206351,
    "latitude": 33.378948
},
{
    "cityname": "盐城",
    "asylumname": "盐城世纪公园",
    "asylumaddress": "新都路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "盐都建设局",
    "managementunithead": "王勇",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.163382,
    "latitude": 33.340778
},
{
    "cityname": "盐城",
    "asylumname": "盐城润都公园",
    "asylumaddress": "盐都西南片",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "盐都建设局",
    "managementunithead": "王勇",
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 120.150798,
    "latitude": 33.309355
},
{
    "cityname": "盐城",
    "asylumname": "盐城友谊公园",
    "asylumaddress": "希望大道",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "开发区建设局",
    "managementunithead": "赵华",
    "createtime": "2011",
    "ischeck": "验收",
    "longitude": 120.22253,
    "latitude": 33.378166
},
{
    "cityname": "盐城",
    "asylumname": "盐城天山公园",
    "asylumaddress": "天山路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "开发区建设局",
    "managementunithead": "赵华",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 120.215539,
    "latitude": 33.35989
},
{
    "cityname": "盐城",
    "asylumname": "盐城东方公园",
    "asylumaddress": "泰山路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "开发区建设局",
    "managementunithead": "赵华",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 120.225879,
    "latitude": 33.383406
},
{
    "cityname": "盐城",
    "asylumname": "盐城聚龙湖公园",
    "asylumaddress": "新都路南",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "城南一局",
    "managementunithead": "刘德龙",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 120.175173,
    "latitude": 33.347509
},
{
    "cityname": "盐城",
    "asylumname": "盐塘河公园",
    "asylumaddress": "人民南路",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 5.5,
    "asylumnumber": 2.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "城南一局",
    "managementunithead": "刘德龙",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.186371,
    "latitude": 33.337744
},
{
    "cityname": "响水",
    "asylumname": "响水大桥公园",
    "asylumaddress": "灌河大桥",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "住建局",
    "managementunithead": "王社成",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.589637,
    "latitude": 34.215864
},
{
    "cityname": "响水",
    "asylumname": "响水湖公园",
    "asylumaddress": "响水湖公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "响水县凤凰房地产开发有限公司",
    "managementunithead": "王奎根",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.609103,
    "latitude": 34.198931
},
{
    "cityname": "响水",
    "asylumname": "响水船闸公园",
    "asylumaddress": "船闸公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "盐城市船闸管理所",
    "managementunithead": "张晓军",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.579583,
    "latitude": 34.198103
},
{
    "cityname": "滨海",
    "asylumname": "滨海南湖公园",
    "asylumaddress": "富康路东侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5.1,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "城管局",
    "managementunithead": "法人代表",
    "createtime": "2011",
    "ischeck": "验收",
    "longitude": 119.830519,
    "latitude": 33.998593
},
{
    "cityname": "滨海",
    "asylumname": "滨海体育中心",
    "asylumaddress": "西片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "体育局",
    "managementunithead": "法人代表",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 120.026609,
    "latitude": 34.092317
},
{
    "cityname": "阜宁",
    "asylumname": "阜宁铁军广场",
    "asylumaddress": "城河路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": "法人代表",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 119.804354,
    "latitude": 33.791885
},
{
    "cityname": "阜宁",
    "asylumname": "阜宁市民广场",
    "asylumaddress": "澳门路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "住建局",
    "managementunithead": "宗光华",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 119.805645,
    "latitude": 33.759151
},
{
    "cityname": "阜宁",
    "asylumname": "阜宁中学体育场",
    "asylumaddress": "上海路",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.1,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "阜宁县中学",
    "managementunithead": "法人代表",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 119.801759,
    "latitude": 33.772125
},
{
    "cityname": "阜宁",
    "asylumname": "阜宁县第一中学体育场",
    "asylumaddress": "北京路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "阜宁县第一中学",
    "managementunithead": "法人代表",
    "createtime": "2011",
    "ischeck": "验收",
    "longitude": 119.70499,
    "latitude": 33.711976
},
{
    "cityname": "阜宁",
    "asylumname": "阜宁城南新区市民广场",
    "asylumaddress": "南京路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "住建局",
    "managementunithead": "宗光华",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 119.805645,
    "latitude": 33.759151
},
{
    "cityname": "阜宁",
    "asylumname": "阜宁城南初中体育场",
    "asylumaddress": "苏州路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "阜宁县实验初中",
    "managementunithead": "法人代表",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 119.70499,
    "latitude": 33.711976
},
{
    "cityname": "建湖",
    "asylumname": "建湖双湖公园",
    "asylumaddress": "城南片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.1,
    "asylumnumber": 1.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": "法人代表",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 119.787008,
    "latitude": 33.451293
},
{
    "cityname": "建湖",
    "asylumname": "建湖体育中心",
    "asylumaddress": "城南片区",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "教育局",
    "managementunithead": "法人代表",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.795839,
    "latitude": 33.453486
},
{
    "cityname": "建湖",
    "asylumname": "建湖文化中心",
    "asylumaddress": "城南片区",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.5,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "文广新局",
    "managementunithead": "法人代表",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.836497,
    "latitude": 33.488908
},
{
    "cityname": "建湖",
    "asylumname": "建湖湖中公园",
    "asylumaddress": "城中片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "住建局",
    "managementunithead": "唐广明",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.836497,
    "latitude": 33.488908
},
{
    "cityname": "建湖",
    "asylumname": "建湖会展中心",
    "asylumaddress": "城中片区",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.3,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "住建局",
    "managementunithead": "唐广明",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.801088,
    "latitude": 33.48235
},
{
    "cityname": "建湖",
    "asylumname": "建湖秀夫公园",
    "asylumaddress": "城南片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.5,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "住建局",
    "managementunithead": "唐广明",
    "createtime": null,
    "ischeck": "验收",
    "longitude": 119.836497,
    "latitude": 33.488908
},
{
    "cityname": "射阳",
    "asylumname": "射阳后羿公园",
    "asylumaddress": "后羿公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5.5,
    "asylumnumber": 2.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林局",
    "managementunithead": "法人代表",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.23245,
    "latitude": 33.771404
},
{
    "cityname": "射阳",
    "asylumname": "射阳城中公园",
    "asylumaddress": "太阳城东门",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "园林局",
    "managementunithead": "法人代表",
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 120.268145,
    "latitude": 33.778569
},
{
    "cityname": "射阳",
    "asylumname": "射阳晨光花园",
    "asylumaddress": "晨光路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.3,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林局",
    "managementunithead": "法人代表",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 120.279505,
    "latitude": 33.745462
},
{
    "cityname": "大丰",
    "asylumname": "大丰东方湿地公园",
    "asylumaddress": "东宁路",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 4.5,
    "asylumnumber": 2.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "城管局",
    "managementunithead": "法人代表",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.486411,
    "latitude": 33.219836
},
{
    "cityname": "大丰",
    "asylumname": "大丰常新公园",
    "asylumaddress": "常新路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "城管局",
    "managementunithead": "法人代表",
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 120.585064,
    "latitude": 33.265909
},
{
    "cityname": "大丰",
    "asylumname": "大丰银杏湖公园",
    "asylumaddress": "益民路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "城管局",
    "managementunithead": "法人代表",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.458361,
    "latitude": 33.191522
},
{
    "cityname": "大丰",
    "asylumname": "大丰施耐庵公园",
    "asylumaddress": "幸福路、康平路交叉口",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "城管局",
    "managementunithead": "法人代表",
    "createtime": "2009",
    "ischeck": "验收",
    "longitude": 120.474994,
    "latitude": 33.211981
},
{
    "cityname": "大丰",
    "asylumname": "大丰奥体中心",
    "asylumaddress": "幸福路",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "体育局",
    "managementunithead": "法人代表",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 120.585064,
    "latitude": 33.265909
},
{
    "cityname": "东台",
    "asylumname": "东台通榆河风光带",
    "asylumaddress": "通榆河西",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 6,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "部分",
    "managementunit": "绿化所",
    "managementunithead": "唐春阳",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.341965,
    "latitude": 32.865838
},
{
    "cityname": "东台",
    "asylumname": "东台体育公园",
    "asylumaddress": "北海路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "部分",
    "managementunit": "体育局",
    "managementunithead": "李春桃",
    "createtime": "2009",
    "ischeck": "验收",
    "longitude": 120.563769,
    "latitude": 32.791443
},
{
    "cityname": "东台",
    "asylumname": "东台市政广场",
    "asylumaddress": "北海路北",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "绿化所",
    "managementunithead": "唐春阳",
    "createtime": "2007",
    "ischeck": "验收",
    "longitude": 120.563769,
    "latitude": 32.791443
},
{
    "cityname": "东台",
    "asylumname": "东台市民广场",
    "asylumaddress": "北海路南",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.9,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "绿化所",
    "managementunithead": "唐春阳",
    "createtime": "2009",
    "ischeck": "验收",
    "longitude": 120.327591,
    "latitude": 32.871424
},
{
    "cityname": "东台",
    "asylumname": "东台东进公园",
    "asylumaddress": "东进桥北侧",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "绿化所",
    "managementunithead": "唐春阳",
    "createtime": "2007",
    "ischeck": "验收",
    "longitude": 120.345635,
    "latitude": 32.858422
},
{
    "cityname": "东台",
    "asylumname": "东台董贤公园",
    "asylumaddress": "东广公路",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "部分",
    "managementunit": "西溪景区",
    "managementunithead": "许  静",
    "createtime": "2013",
    "ischeck": "验收",
    "longitude": 120.563769,
    "latitude": 32.791443
},
{
    "cityname": "东台",
    "asylumname": "东台金墩广场",
    "asylumaddress": "新桥新村",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "金墩社区",
    "managementunithead": "周冯琴",
    "createtime": "2010",
    "ischeck": "验收",
    "longitude": 120.312275,
    "latitude": 32.85422
},
{
    "cityname": "扬州",
    "asylumname": "扬州市人防应急疏散基地",
    "asylumaddress": "扬子江南路",
    "placelevel": "固定",
    "placetype": "场所",
    "asylumarea": 3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "扬州市民防局",
    "managementunithead": null,
    "createtime": "2005",
    "ischeck": "否",
    "longitude": 119.427778,
    "latitude": 32.408505
},
{
    "cityname": "扬州",
    "asylumname": "扬州体育公园",
    "asylumaddress": "文昌西路",
    "placelevel": "固定",
    "placetype": "场所",
    "asylumarea": 18.8,
    "asylumnumber": 9.4,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "新城西区管委会",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "否",
    "longitude": 119.3733,
    "latitude": 32.398586
},
{
    "cityname": "扬州",
    "asylumname": "扬州宋夹城遗址公园",
    "asylumaddress": "蜀冈瘦西湖风景区",
    "placelevel": "固定",
    "placetype": "场所",
    "asylumarea": 10,
    "asylumnumber": 5,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "蜀冈瘦西湖风景区",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "否",
    "longitude": 119.431736,
    "latitude": 32.415807
},
{
    "cityname": "扬州",
    "asylumname": "扬州大学附属中学东部分校",
    "asylumaddress": "安康路",
    "placelevel": "固定",
    "placetype": "场所",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "扬大附中",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "否",
    "longitude": 119.47274,
    "latitude": 32.411649
},
{
    "cityname": "扬州",
    "asylumname": "扬州职业大学",
    "asylumaddress": "文昌西路",
    "placelevel": "固定",
    "placetype": "场所",
    "asylumarea": 6.5,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "扬州职大",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "否",
    "longitude": 119.386006,
    "latitude": 32.401507
},
{
    "cityname": "扬州",
    "asylumname": "扬州万花园",
    "asylumaddress": "长春路",
    "placelevel": "固定",
    "placetype": "场所",
    "asylumarea": 7.3,
    "asylumnumber": 3.6,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "扬州瘦西湖公园",
    "managementunithead": null,
    "createtime": "2013",
    "ischeck": "否",
    "longitude": 119.426834,
    "latitude": 32.417186
},
{
    "cityname": "扬州",
    "asylumname": "扬州生态园",
    "asylumaddress": "江都区政府南侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 3.9,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "江都区园林管理处",
    "managementunithead": "许宏海",
    "createtime": "2006",
    "ischeck": "是",
    "longitude": 119.454984,
    "latitude": 32.331776
},
{
    "cityname": "扬州",
    "asylumname": "扬州龙川广场",
    "asylumaddress": "引江桥南侧",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 15,
    "asylumnumber": 7.5,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "扬州龙腾园林工程有限公司",
    "managementunithead": "孙进东",
    "createtime": "2003",
    "ischeck": "是",
    "longitude": 119.567772,
    "latitude": 32.423703
},
{
    "cityname": "高邮",
    "asylumname": "高邮蝶园广场避难场所",
    "asylumaddress": "蝶园广场",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 8.8,
    "asylumnumber": 4.4,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "城管局",
    "managementunithead": null,
    "createtime": "2007",
    "ischeck": "是",
    "longitude": 119.449165,
    "latitude": 32.782625
},
{
    "cityname": "高邮",
    "asylumname": "高邮海潮广场避难场所",
    "asylumaddress": "海潮广场",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 8.1,
    "asylumnumber": 4,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "政府办",
    "managementunithead": null,
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 119.503407,
    "latitude": 32.835944
},
{
    "cityname": "高邮",
    "asylumname": "高邮净土寺广场避难场所",
    "asylumaddress": "净土寺广场",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 3.8,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "有",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "建设局",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 119.503407,
    "latitude": 32.835944
},
{
    "cityname": "宝应",
    "asylumname": "宝应安宜高中",
    "asylumaddress": "白田南路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 6,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 119.343557,
    "latitude": 33.233183
},
{
    "cityname": "宝应",
    "asylumname": "宝应民防疏散基地",
    "asylumaddress": "生态园",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 233.7,
    "asylumnumber": 0,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "人防办",
    "managementunithead": "高贵荣",
    "createtime": "2013",
    "ischeck": "否",
    "longitude": 119.455651,
    "latitude": 33.225834
},
{
    "cityname": "仪征市",
    "asylumname": "仪征市万博社区",
    "asylumaddress": "万年大道",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 0.8,
    "asylumnumber": 0.4,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "万博物业公司",
    "managementunithead": "朱广荣",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 119.1901,
    "latitude": 32.290527
},
{
    "cityname": "镇江",
    "asylumname": "镇江镇江体育会展中心",
    "asylumaddress": "南徐新城",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 17.3,
    "asylumnumber": 3.5,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": "配置齐全",
    "managementunit": "镇江市体育产业发展有限责任公司",
    "managementunithead": "潘文宇",
    "createtime": null,
    "ischeck": "是",
    "longitude": 130.936162,
    "latitude": 45.305603
},
{
    "cityname": "镇江",
    "asylumname": "镇江南山景区西入口",
    "asylumaddress": "镇江南山风景区西部",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 11,
    "asylumnumber": 3.5,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "三山管委会",
    "managementunithead": "李明俊",
    "createtime": null,
    "ischeck": "是",
    "longitude": 119.454891,
    "latitude": 32.17849
},
{
    "cityname": "镇江",
    "asylumname": "镇江人民广场",
    "asylumaddress": "镇江新区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 5.1,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "新区公园管理处",
    "managementunithead": "曹小飞",
    "createtime": null,
    "ischeck": "是",
    "longitude": 119.4404,
    "latitude": 32.136832
},
{
    "cityname": "镇江",
    "asylumname": "镇江人民政府广场",
    "asylumaddress": "丹徒新区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 14,
    "asylumnumber": 5,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "广场办",
    "managementunithead": "陈海洲",
    "createtime": null,
    "ischeck": "是",
    "longitude": 110.71073,
    "latitude": 21.869178
},
{
    "cityname": "镇江",
    "asylumname": "镇江丹徒实验学校",
    "asylumaddress": "丹徒新区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 6,
    "asylumnumber": 2,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "丹徒教育局",
    "managementunithead": "张伟荣",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 119.430867,
    "latitude": 32.127082
},
{
    "cityname": "丹阳",
    "asylumname": "丹阳天地石刻园",
    "asylumaddress": "丹阳市开发区",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 40,
    "asylumnumber": 8,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "丹阳市民防局",
    "managementunithead": "陈豪杰",
    "createtime": null,
    "ischeck": "是",
    "longitude": 119.663305,
    "latitude": 32.064292
},
{
    "cityname": "丹阳",
    "asylumname": "丹阳行政中心广场",
    "asylumaddress": "开发区",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 3.5,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "丹阳市民防局",
    "managementunithead": "陈豪杰",
    "createtime": "2008",
    "ischeck": "是",
    "longitude": 119.644304,
    "latitude": 31.960263
},
{
    "cityname": "扬中",
    "asylumname": "扬中城西公园",
    "asylumaddress": "金纬西路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 4.2,
    "asylumnumber": 3.5,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "园林局",
    "managementunithead": "周丁文",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 119.804919,
    "latitude": 32.238112
},
{
    "cityname": "扬中",
    "asylumname": "扬中城北公园",
    "asylumaddress": "环城北路",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 3.2,
    "asylumnumber": 2.7,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "园林局",
    "managementunithead": "周丁文",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 119.826678,
    "latitude": 32.250686
},
{
    "cityname": "扬中",
    "asylumname": "扬中曲棍球场",
    "asylumaddress": "环城西路",
    "placelevel": "固定",
    "placetype": "建筑",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "教育局",
    "managementunithead": "张源泉",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 119.845138,
    "latitude": 32.189469
},
{
    "cityname": "扬中",
    "asylumname": "扬中雨润广场",
    "asylumaddress": "江洲中路",
    "placelevel": "固定",
    "placetype": "建筑",
    "asylumarea": 1.2,
    "asylumnumber": 1,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "雨润集团",
    "managementunithead": "周彪",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 119.832292,
    "latitude": 32.24424
},
{
    "cityname": "句容",
    "asylumname": "句容葛仙湖公园",
    "asylumaddress": "句容市",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 10,
    "asylumnumber": 5,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "句容市住建局",
    "managementunithead": "姚辉",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 119.166882,
    "latitude": 31.951653
},
{
    "cityname": "句容",
    "asylumname": "句容房家坝公园",
    "asylumaddress": "句容市",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 7,
    "asylumnumber": 3,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "句容市城管局",
    "managementunithead": "王永珍",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 119.188862,
    "latitude": 31.953234
},
{
    "cityname": "句容",
    "asylumname": "句容贾纪山公园",
    "asylumaddress": "句容市",
    "placelevel": "固定",
    "placetype": "场地",
    "asylumarea": 8,
    "asylumnumber": 4,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": null,
    "managementunit": "句容市城管局",
    "managementunithead": "王永珍",
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 119.201818,
    "latitude": 31.938466
},
{
    "cityname": "泰州",
    "asylumname": "泰州人民公园",
    "asylumaddress": "海陵路东，鼓楼路西，梅兰路南，永晖路北",
    "placelevel": "固定",
    "placetype": "二级",
    "asylumarea": 17,
    "asylumnumber": 8.5,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "市区园林管理处",
    "managementunithead": "陈颖",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 119.929435,
    "latitude": 32.465583
},
{
    "cityname": "泰州",
    "asylumname": "泰州人民广场",
    "asylumaddress": "凤凰路南，永兴路北",
    "placelevel": "固定",
    "placetype": "二级",
    "asylumarea": 10,
    "asylumnumber": 5,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "市区园林管理处",
    "managementunithead": "陈颖",
    "createtime": "2012",
    "ischeck": "是",
    "longitude": 119.929486,
    "latitude": 32.457256
},
{
    "cityname": "泰州",
    "asylumname": "泰州泰山公园",
    "asylumaddress": "公园路20号",
    "placelevel": "固定",
    "placetype": "二级",
    "asylumarea": 11.4,
    "asylumnumber": 5.7,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "市区园林管理处",
    "managementunithead": "陈颖",
    "createtime": "2013",
    "ischeck": "是",
    "longitude": 119.913967,
    "latitude": 32.491024
},
{
    "cityname": "泰州",
    "asylumname": "泰州天德湖公园",
    "asylumaddress": "海陵路西、鼓楼路东、海军大道北",
    "placelevel": "中心",
    "placetype": "一级",
    "asylumarea": 40,
    "asylumnumber": 20,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "天德湖公园",
    "managementunithead": "校剑钧",
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 119.930242,
    "latitude": 32.431939
},
{
    "cityname": "泰州",
    "asylumname": "泰州九龙镇文化广",
    "asylumaddress": "九龙镇政府北侧",
    "placelevel": "固定",
    "placetype": "二级",
    "asylumarea": 3,
    "asylumnumber": 2,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "市区园林管理处",
    "managementunithead": "陈颖",
    "createtime": "2009",
    "ischeck": null,
    "longitude": 119.857216,
    "latitude": 32.498343
},
{
    "cityname": "兴化",
    "asylumname": "兴化昭阳湖公园",
    "asylumaddress": "昭阳湖公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8.9,
    "asylumnumber": 0.4,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2009",
    "ischeck": null,
    "longitude": 119.996418,
    "latitude": 32.961954
},
{
    "cityname": "兴化",
    "asylumname": "兴化森林公园",
    "asylumaddress": "森林公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8,
    "asylumnumber": 0.3,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2007",
    "ischeck": null,
    "longitude": 119.851406,
    "latitude": 32.959196
},
{
    "cityname": "兴化",
    "asylumname": "兴化楚水园",
    "asylumaddress": "楚水园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5.6,
    "asylumnumber": 0.2,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2004",
    "ischeck": null,
    "longitude": 119.844766,
    "latitude": 32.942969
},
{
    "cityname": "兴化",
    "asylumname": "兴化板桥竹石园",
    "asylumaddress": "板桥竹石园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5,
    "asylumnumber": 0.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2007",
    "ischeck": null,
    "longitude": 119.996418,
    "latitude": 32.961954
},
{
    "cityname": "兴化",
    "asylumname": "兴化时代广场",
    "asylumaddress": "时代广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 0.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2005",
    "ischeck": null,
    "longitude": 120.139681,
    "latitude": 32.734229
},
{
    "cityname": "兴化",
    "asylumname": "兴化兴健园",
    "asylumaddress": "兴健园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.7,
    "asylumnumber": 0.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2008",
    "ischeck": null,
    "longitude": 119.85146,
    "latitude": 32.930359
},
{
    "cityname": "兴化",
    "asylumname": "兴化阳山公园　",
    "asylumaddress": "阳山公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 0.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2008",
    "ischeck": null,
    "longitude": 119.826235,
    "latitude": 32.940728
},
{
    "cityname": "兴化",
    "asylumname": "兴化人民公园　",
    "asylumaddress": "人民公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 0.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": null,
    "ischeck": null,
    "longitude": 119.847508,
    "latitude": 32.945002
},
{
    "cityname": "兴化",
    "asylumname": "兴化拱极台公园",
    "asylumaddress": "拱极台公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.1,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "1997",
    "ischeck": null,
    "longitude": 119.847251,
    "latitude": 32.945773
},
{
    "cityname": "兴化",
    "asylumname": "兴化生态园",
    "asylumaddress": "生态园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 79,
    "asylumnumber": 2.6,
    "basicfacilitymatch": null,
    "commonfacilitymatch": null,
    "comprebasicfacilitymatch": null,
    "managementunit": "园林处",
    "managementunithead": "刘震祥",
    "createtime": "2008",
    "ischeck": null,
    "longitude": 119.996418,
    "latitude": 32.961954
},
{
    "cityname": "南京",
    "asylumname": "南京河西中央公园应急避难场所场所",
    "asylumaddress": "河西中央公园地下",
    "placelevel": "中心",
    "placetype": "建筑型",
    "asylumarea": 5.3,
    "asylumnumber": 1,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": "配置齐全",
    "managementunit": "市人防",
    "managementunithead": "沧军",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 118.732505,
    "latitude": 32.001278
},
{
    "cityname": "南京",
    "asylumname": "南京大行宫应急避难场所",
    "asylumaddress": "大行宫市民广场地下",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 2.3,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "配置齐全",
    "commonfacilitymatch": "配置齐全",
    "comprebasicfacilitymatch": "配置齐全",
    "managementunit": "市人防",
    "managementunithead": "满其军",
    "createtime": "2012",
    "ischeck": "验收",
    "longitude": 118.817954,
    "latitude": 32.04788
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区张泾中学",
    "asylumaddress": "张泾中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "张泾中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.445725,
    "latitude": 31.664034
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区泾西小学",
    "asylumaddress": "泾西小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "泾西小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.441192,
    "latitude": 31.663669
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区龙潭路东侧绿地",
    "asylumaddress": "龙潭路东侧绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.8,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.491008,
    "latitude": 31.615587
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区怀仁中学北绿地",
    "asylumaddress": "怀仁中学北绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.2,
    "asylumnumber": 1.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.501034,
    "latitude": 31.680016
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区规划学校（杏西路与西星路交叉口）",
    "asylumaddress": "规划学校（杏西路与西星路交叉口）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.491008,
    "latitude": 31.615587
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区东港体育馆",
    "asylumaddress": "东港体育馆",
    "placelevel": "中心",
    "placetype": "建筑型",
    "asylumarea": 7.8,
    "asylumnumber": 3.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.491008,
    "latitude": 31.615587
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区规划绿地（兴港路与锡沙路交叉）",
    "asylumaddress": "规划绿地（兴港路与锡沙路交叉）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.354639,
    "latitude": 31.635266
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区港下学校",
    "asylumaddress": "港下学校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.2,
    "asylumnumber": 2.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "港下学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.552044,
    "latitude": 31.707579
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区建港路南绿地",
    "asylumaddress": "建港路南绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.4,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.561236,
    "latitude": 31.725513
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区规划绿地（锡东大道与安泰二路交叉口）",
    "asylumaddress": "规划绿地（锡东大道与安泰二路交叉口）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5,
    "asylumnumber": 2.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.48236,
    "latitude": 31.623454
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区规划绿地（翠屏山路东侧）",
    "asylumaddress": "规划绿地（翠屏山路东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.6,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.463398,
    "latitude": 31.624741
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区锡东大道与大成路交叉口",
    "asylumaddress": "锡东大道与大成路交叉口",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公建中心",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.483574,
    "latitude": 31.603752
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区厚桥中学",
    "asylumaddress": "厚桥中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "厚桥中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.515146,
    "latitude": 31.576538
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区锡东大道与安盛路交叉口绿地",
    "asylumaddress": "锡东大道与安盛路交叉口绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 6.5,
    "asylumnumber": 3.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.482432,
    "latitude": 31.621455
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区廊下小学",
    "asylumaddress": "廊下小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "廊下小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.526398,
    "latitude": 31.616572
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区羊尖初中",
    "asylumaddress": "羊尖初中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.6,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "羊尖初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.549431,
    "latitude": 31.628097
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区育才路东端",
    "asylumaddress": "育才路东端",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公建中心",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.546779,
    "latitude": 31.620719
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区规划绿地（甘露大街东侧）",
    "asylumaddress": "规划绿地（甘露大街东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.2,
    "asylumnumber": 2.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.586558,
    "latitude": 31.55102
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区规划体育场馆（尚沙路西侧）",
    "asylumaddress": "规划体育场馆（尚沙路西侧）",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 3.9,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.491008,
    "latitude": 31.615587
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区甘露学校",
    "asylumaddress": "甘露学校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.9,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "甘露学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.586761,
    "latitude": 31.554983
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区堰桥实验学校",
    "asylumaddress": "堰桥实验学校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 10.8,
    "asylumnumber": 4.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "省锡中实验学校",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.291176,
    "latitude": 31.694428
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区省锡中实验分校",
    "asylumaddress": "省锡中实验分校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.7,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "省锡中实验学校",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.215294,
    "latitude": 31.656376
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区规划体育场馆（东园路与芙蓉四路交叉口）",
    "asylumaddress": "规划体育场馆（东园路与芙蓉四路交叉口）",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 2.5,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.358735,
    "latitude": 31.637731
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区八士中学",
    "asylumaddress": "八士中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.6,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "八士中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.397084,
    "latitude": 31.650824
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区八士实验小学",
    "asylumaddress": "八士实验小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.7,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.406168,
    "latitude": 31.659146
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区春蕾小学",
    "asylumaddress": "春蕾小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.9,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "春蕾小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.491008,
    "latitude": 31.615587
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区沧下小学",
    "asylumaddress": "沧下小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "沧下小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.491008,
    "latitude": 31.615587
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区查桥实验小学",
    "asylumaddress": "查桥实验小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.3,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "查桥实验小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.431244,
    "latitude": 31.607818
},
{
    "cityname": "新区",
    "asylumname": "新区梅村中学",
    "asylumaddress": "梅村中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 11.1,
    "asylumnumber": 5.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "梅村中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 117.550878,
    "latitude": 30.399931
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区玉祁中心小学",
    "asylumaddress": "玉祁中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "玉祁中心小学",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.199357,
    "latitude": 31.719191
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区玉祁高中",
    "asylumaddress": "玉祁高中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.1,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "玉祁高中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.186534,
    "latitude": 31.710524
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区民主小学",
    "asylumaddress": "民主小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "民主小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.215294,
    "latitude": 31.656376
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区兴玉路与工业大道交叉口东北侧",
    "asylumaddress": "街头绿地( 兴玉路与工业大道交叉口东北侧)",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "玉祁街道",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.215294,
    "latitude": 31.656376
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区前洲镇中心小学",
    "asylumaddress": "前洲镇中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.4,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "前洲镇中心小学",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.230269,
    "latitude": 31.60488
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区公园(前进路与从商路交叉口西南侧)",
    "asylumaddress": "公园(前进路与从商路交叉口西南侧)",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "前洲街道",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.097771,
    "latitude": 31.586128
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区华圻小学",
    "asylumaddress": "华圻小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.7,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "华圻小学",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.159912,
    "latitude": 31.660571
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区省锡山高级中学",
    "asylumaddress": "省锡山高级中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4,
    "asylumnumber": 2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "省锡山高级中学",
    "managementunithead": null,
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 120.289737,
    "latitude": 31.682759
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区洛社高级中学",
    "asylumaddress": "洛社高级中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.4,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "洛社高级中学",
    "managementunithead": null,
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 120.199996,
    "latitude": 31.645821
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区洛社镇中心小学",
    "asylumaddress": "洛社镇中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.1,
    "asylumnumber": 1.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "洛社镇中心小学",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.192697,
    "latitude": 31.649784
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区陆区小学",
    "asylumaddress": "陆区小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.7,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "陆区小学",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.097679,
    "latitude": 31.578757
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区阳山中学",
    "asylumaddress": "阳山中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "阳山中学",
    "managementunithead": null,
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 120.13301,
    "latitude": 31.597744
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区钱桥中学",
    "asylumaddress": "钱桥中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.1,
    "asylumnumber": 1.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "钱桥中学",
    "managementunithead": null,
    "createtime": "2011",
    "ischeck": "是",
    "longitude": 120.229817,
    "latitude": 31.609297
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区钱桥中心小学",
    "asylumaddress": "钱桥中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.6,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "钱桥中心小学",
    "managementunithead": null,
    "createtime": "2009",
    "ischeck": "是",
    "longitude": 120.230324,
    "latitude": 31.605104
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区藕塘中学",
    "asylumaddress": "藕塘中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "藕塘中学",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.193754,
    "latitude": 31.596906
},
{
    "cityname": "惠山区",
    "asylumname": "惠山区藕塘小学",
    "asylumaddress": "藕塘小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.3,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "藕塘小学",
    "managementunithead": null,
    "createtime": "2010",
    "ischeck": "是",
    "longitude": 120.19197,
    "latitude": 31.596079
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区规划职业学校（园区路与纬三路交叉口东北侧)",
    "asylumaddress": "规划职业学校（园区路与纬三路交叉口东北侧)",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 6.7,
    "asylumnumber": 3.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.117921,
    "latitude": 31.474449
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区体育场馆(西环线与园中北路交叉口西南侧)",
    "asylumaddress": "体育场馆(西环线与园中北路交叉口西南侧)",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 4.6,
    "asylumnumber": 2.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.269862,
    "latitude": 31.551409
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区胡埭高级中学（立人中学）",
    "asylumaddress": "胡埭高级中学（立人中学）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.4,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "胡埭高级中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.130764,
    "latitude": 31.550802
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区胡埭第二小学",
    "asylumaddress": "胡埭第二小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "胡埭第二小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.130764,
    "latitude": 31.550802
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区胡埭中心小学",
    "asylumaddress": "胡埭中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.5,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": "2006",
    "ischeck": "是",
    "longitude": 120.128071,
    "latitude": 31.555161
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区规划停车场（灵山停车场）",
    "asylumaddress": "规划停车场（灵山停车场）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "公建中心",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.248502,
    "latitude": 31.466579
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区凤翔试验学校",
    "asylumaddress": "凤翔试验学校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.8,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "凤翔实验学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区北塘区政府前广场",
    "asylumaddress": "北塘区政府前广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.1,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "北塘区建设局",
    "managementunithead": "万建农",
    "createtime": "2007",
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区山明中学",
    "asylumaddress": "山明中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "山明中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区山北中学",
    "asylumaddress": "山北中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "山北中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区山北中心小学",
    "asylumaddress": "山北中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.3,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "山北中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区惠钱路西侧绿地",
    "asylumaddress": "惠钱路西侧绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 5.3,
    "asylumnumber": 2.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.258051,
    "latitude": 31.59999
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区五河小学",
    "asylumaddress": "五河小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "五河小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区吴桥中心小学",
    "asylumaddress": "吴桥中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "吴桥中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.290653,
    "latitude": 31.599466
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区积余街九年制学校",
    "asylumaddress": "积余街九年制学校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "积余街九年制学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区北尖公园",
    "asylumaddress": "北尖公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.5,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市城投公司",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "北塘区",
    "asylumname": "北塘区江尖公园",
    "asylumaddress": "江尖公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市城投公司",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.282126,
    "latitude": 31.609369
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区胜利门广场",
    "asylumaddress": "胜利门广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "崇安区政府",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.305826,
    "latitude": 31.587821
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区火车站广场",
    "asylumaddress": "火车站广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "崇安区政府",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.311503,
    "latitude": 31.590877
},
{
    "cityname": "南长区",
    "asylumname": "南长区市体育公园",
    "asylumaddress": "市体育公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 7,
    "asylumnumber": 3.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.308317,
    "latitude": 31.571097
},
{
    "cityname": "南长区",
    "asylumname": "南长区夹城里小学",
    "asylumaddress": "夹城里小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "夹城里小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区扬名中心小学",
    "asylumaddress": "扬名中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "扬名中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区教育学院附中",
    "asylumaddress": "教育学院附中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育学院附中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区社区绿地(建业北路)",
    "asylumaddress": "社区绿地(建业北路)",
    "placelevel": "紧急",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "不齐全",
    "commonfacilitymatch": "不齐全",
    "comprebasicfacilitymatch": "不齐全",
    "managementunit": "南长区政府",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区南长街小学",
    "asylumaddress": "南长街小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "南长街小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区塘南中学",
    "asylumaddress": "塘南中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "塘南中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区辅仁中学",
    "asylumaddress": "辅仁中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.1,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "辅仁中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.32434,
    "latitude": 31.546419
},
{
    "cityname": "南长区",
    "asylumname": "南长区太湖广场南广场",
    "asylumaddress": "太湖广场南广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 8.4,
    "asylumnumber": 4.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": "2006.8",
    "ischeck": "是",
    "longitude": 120.311791,
    "latitude": 31.556715
},
{
    "cityname": "南长区",
    "asylumname": "南长区五星小学",
    "asylumaddress": "五星小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "五星小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区广益初中",
    "asylumaddress": "广益初中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "广益初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.339702,
    "latitude": 31.602071
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区广益中心小学",
    "asylumaddress": "广益中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "广益中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.328903,
    "latitude": 31.597341
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区广丰初中",
    "asylumaddress": "广丰初中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "广丰初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.328903,
    "latitude": 31.597341
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区广勤初中",
    "asylumaddress": "广勤初中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "广勤初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.328903,
    "latitude": 31.597341
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区通江实验小学",
    "asylumaddress": "通江实验小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "通江实验小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.328903,
    "latitude": 31.597341
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区北中路滨河绿地",
    "asylumaddress": "北中路滨河绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.9,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.328903,
    "latitude": 31.597341
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区规划小学（学前东路与广南路交叉口东侧）",
    "asylumaddress": "规划小学（学前东路与广南路交叉口东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.342249,
    "latitude": 31.586632
},
{
    "cityname": "崇安区",
    "asylumname": "崇安区靖海公园",
    "asylumaddress": "靖海公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.328903,
    "latitude": 31.597341
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区锡山区人民政府前广场",
    "asylumaddress": "锡山区人民政府前广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.6,
    "asylumnumber": 2.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.491008,
    "latitude": 31.615587
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区天一中学",
    "asylumaddress": "天一中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "天一中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.370606,
    "latitude": 31.599332
},
{
    "cityname": "锡山区",
    "asylumname": "锡山区天一中学东侧广场",
    "asylumaddress": "天一中学东侧广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.6,
    "asylumnumber": 2.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.370606,
    "latitude": 31.599332
},
{
    "cityname": "新区",
    "asylumname": "新区长江初中",
    "asylumaddress": "长江初中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "长江初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.367782,
    "latitude": 31.556778
},
{
    "cityname": "新区",
    "asylumname": "新区长江路小学",
    "asylumaddress": "长江路小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "长江路小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 122.156753,
    "latitude": 40.26801
},
{
    "cityname": "新区",
    "asylumname": "新区叙丰小学",
    "asylumaddress": "叙丰小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "叙丰小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 116.857438,
    "latitude": 40.381414
},
{
    "cityname": "新区",
    "asylumname": "新区旺庄初中",
    "asylumaddress": "旺庄初中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.4,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "旺庄初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.37216,
    "latitude": 31.562579
},
{
    "cityname": "新区",
    "asylumname": "新区旺庄实验小学",
    "asylumaddress": "旺庄实验小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "旺庄实验小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.359475,
    "latitude": 31.548302
},
{
    "cityname": "新区",
    "asylumname": "新区无锡职业高中",
    "asylumaddress": "无锡职业高中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "无锡职业高中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.438242,
    "latitude": 31.480333
},
{
    "cityname": "新区",
    "asylumname": "新区规划小学（溪仕路与金城路交叉口北侧）",
    "asylumaddress": "规划小学（溪仕路与金城路交叉口北侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.422119,
    "latitude": 31.555314
},
{
    "cityname": "新区",
    "asylumname": "新区新区中央公园",
    "asylumaddress": "新区中央公园",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 8.9,
    "asylumnumber": 4.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.367782,
    "latitude": 31.556778
},
{
    "cityname": "新区",
    "asylumname": "新区规划中学（旺庄东路）",
    "asylumaddress": "规划中学（旺庄东路）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.4,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.367782,
    "latitude": 31.556778
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区河埒广场",
    "asylumaddress": "河埒广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.6,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "滨湖区城投公司",
    "managementunithead": null,
    "createtime": "2002",
    "ischeck": "是",
    "longitude": 120.272151,
    "latitude": 31.56918
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区青山高级中学",
    "asylumaddress": "青山高级中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.4,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "青山高级中学",
    "managementunithead": "刘兴璋",
    "createtime": "2005",
    "ischeck": "是",
    "longitude": 120.279367,
    "latitude": 31.57934
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区青山初中（江大附中）",
    "asylumaddress": "青山初中（江大附中）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "江大附中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.248502,
    "latitude": 31.466579
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区青山小学（育英小学）",
    "asylumaddress": "青山小学（育英小学）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "育英小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.248502,
    "latitude": 31.466579
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区河埒中学",
    "asylumaddress": "河埒中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "河埒中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.246797,
    "latitude": 31.557737
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区育红小学",
    "asylumaddress": "育红小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "育红小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.255089,
    "latitude": 31.567043
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区大池路体育馆",
    "asylumaddress": "大池路体育馆",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 2,
    "asylumnumber": 1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.259542,
    "latitude": 31.57185
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区梁溪路西端绿地",
    "asylumaddress": "梁溪路西端绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.2,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.261474,
    "latitude": 31.566794
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区体育场馆",
    "asylumaddress": "体育场馆",
    "placelevel": "固定",
    "placetype": "建筑型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.269862,
    "latitude": 31.551409
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区蠡园中心小学",
    "asylumaddress": "蠡园中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "蠡园中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.252674,
    "latitude": 31.555568
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区市体育中心",
    "asylumaddress": "市体育中心",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 25.2,
    "asylumnumber": 12.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市体育局",
    "managementunithead": "周成华",
    "createtime": "2000",
    "ischeck": "是",
    "longitude": 120.269291,
    "latitude": 31.551332
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区规划高中（湖滨路与隐秀路交叉口东侧）",
    "asylumaddress": "规划高中（湖滨路与隐秀路交叉口东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.3,
    "asylumnumber": 2.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.290486,
    "latitude": 31.553724
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区蠡湖中心小学",
    "asylumaddress": "蠡湖中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "蠡湖中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.28126,
    "latitude": 31.536373
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区蠡园中学",
    "asylumaddress": "蠡园中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "蠡园中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.265138,
    "latitude": 31.546072
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区望湖路广场",
    "asylumaddress": "望湖路广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.7,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.205545,
    "latitude": 31.546649
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区蠡湖中央公园",
    "asylumaddress": "蠡湖中央公园",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 16.8,
    "asylumnumber": 8.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.255188,
    "latitude": 31.527865
},
{
    "cityname": "南长区",
    "asylumname": "南长区江南中学初中部",
    "asylumaddress": "江南中学初中部",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "江南中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.300033,
    "latitude": 31.551862
},
{
    "cityname": "南长区",
    "asylumname": "南长区锡师附小分部",
    "asylumaddress": "锡师附小分部",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "锡师附小",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区新联小学",
    "asylumaddress": "新联小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.1,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "新联小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.309929,
    "latitude": 31.539658
},
{
    "cityname": "南长区",
    "asylumname": "南长区规划中学（中桥二村东侧）",
    "asylumaddress": "规划中学（中桥二村东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.315798,
    "latitude": 31.542866
},
{
    "cityname": "南长区",
    "asylumname": "南长区梁韵大桥街头绿地",
    "asylumaddress": "梁韵大桥街头绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.4,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "不齐全",
    "commonfacilitymatch": "不齐全",
    "comprebasicfacilitymatch": "不齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.294436,
    "latitude": 31.565399
},
{
    "cityname": "南长区",
    "asylumname": "南长区金匮桥头绿地",
    "asylumaddress": "金匮桥头绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.7,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "不齐全",
    "commonfacilitymatch": "不齐全",
    "comprebasicfacilitymatch": "不齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.329794,
    "latitude": 31.543129
},
{
    "cityname": "南长区",
    "asylumname": "南长区街头绿地（金石路与芦中路交叉口）",
    "asylumaddress": "街头绿地（金石路与芦中路交叉口）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.1,
    "asylumnumber": 0.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.330372,
    "latitude": 31.527601
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区东绛小学",
    "asylumaddress": "东绛小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "东绛小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.312254,
    "latitude": 31.516049
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区无锡职业技术学院",
    "asylumaddress": "无锡职业技术学院",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "无锡职业技术学院",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.279259,
    "latitude": 31.50091
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区江南大学北部1",
    "asylumaddress": "江南大学北部1",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.5,
    "asylumnumber": 2.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "江南大学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.279777,
    "latitude": 31.494029
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区江南大学北部2",
    "asylumaddress": "江南大学北部2",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.8,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "江南大学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.279777,
    "latitude": 31.494037
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区太湖高中",
    "asylumaddress": "太湖高中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.6,
    "asylumnumber": 2.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "太湖高中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.29313,
    "latitude": 31.498148
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区格致中学",
    "asylumaddress": "格致中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "格致中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.292978,
    "latitude": 31.492969
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区金桥小学",
    "asylumaddress": "金桥小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.9,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "金桥小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.306555,
    "latitude": 31.497971
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区新雪浪中学",
    "asylumaddress": "新雪浪中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.8,
    "asylumnumber": 0.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "新雪浪中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.295279,
    "latitude": 31.473475
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区雪浪中心小学",
    "asylumaddress": "雪浪中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "雪浪中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.294212,
    "latitude": 31.478
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区街头绿地（震泽路与立信大道交叉口南北侧）",
    "asylumaddress": "街头绿地（震泽路与立信大道交叉口南北侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.3,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.313917,
    "latitude": 31.47821
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区丰润路沿河绿地",
    "asylumaddress": "丰润路沿河绿地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.7,
    "asylumnumber": 1.9,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.248502,
    "latitude": 31.466579
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区街头绿地（五湖大道与具区路交叉口）",
    "asylumaddress": "街头绿地（五湖大道与具区路交叉口）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.5,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.301286,
    "latitude": 31.462297
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区尚贤河公园",
    "asylumaddress": "尚贤河公园",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 2.9,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.248502,
    "latitude": 31.466579
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区落霞初中（南湖中学）",
    "asylumaddress": "落霞初中（南湖中学）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.5,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "南湖中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.327167,
    "latitude": 31.51054
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区无锡外国语学校",
    "asylumaddress": "无锡外国语学校",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "无锡外国语学校",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.335467,
    "latitude": 31.499616
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区华庄初中",
    "asylumaddress": "华庄初中",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.6,
    "asylumnumber": 1.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "华庄初中",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.350456,
    "latitude": 31.486945
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区华庄中心小学",
    "asylumaddress": "华庄中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.7,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "华庄中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.350455,
    "latitude": 31.484403
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区无锡第六高级中学",
    "asylumaddress": "无锡第六高级中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.4,
    "asylumnumber": 1.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "无锡第六高级中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.320733,
    "latitude": 31.547091
},
{
    "cityname": "新区",
    "asylumname": "新区中心公园（清晏路与净慧西路交叉口东侧）",
    "asylumaddress": "中心公园（清晏路与净慧西路交叉口东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.314316,
    "latitude": 31.475092
},
{
    "cityname": "新区",
    "asylumname": "新区新安中学",
    "asylumaddress": "新安中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.8,
    "asylumnumber": 1.4,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "新安中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 129.120935,
    "latitude": 44.463243
},
{
    "cityname": "新区",
    "asylumname": "新区新安中心小学",
    "asylumaddress": "新安中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.6,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "新安中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 125.683106,
    "latitude": 43.75502
},
{
    "cityname": "滨湖区",
    "asylumname": "滨湖区南泉中心小学",
    "asylumaddress": "南泉中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.4,
    "asylumnumber": 1.2,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "南泉中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.248502,
    "latitude": 31.466579
},
{
    "cityname": "新区",
    "asylumname": "新区棒球训练基地",
    "asylumaddress": "棒球训练基地",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.2,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 116.857438,
    "latitude": 40.381414
},
{
    "cityname": "新区",
    "asylumname": "新区九年制学校（旺庄路与锡仕路交叉口东侧）",
    "asylumaddress": "九年制学校（旺庄路与锡仕路交叉口东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 1.3,
    "asylumnumber": 0.7,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "教育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 127.346568,
    "latitude": 43.74313
},
{
    "cityname": "新区",
    "asylumname": "新区硕放中学",
    "asylumaddress": "硕放中学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 3.3,
    "asylumnumber": 1.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "硕放中学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.450033,
    "latitude": 31.469655
},
{
    "cityname": "新区",
    "asylumname": "新区硕放中心小学",
    "asylumaddress": "硕放中心小学",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.9,
    "asylumnumber": 1.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "硕放中心小学",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.458035,
    "latitude": 31.491368
},
{
    "cityname": "新区",
    "asylumname": "新区薛典路西侧广场",
    "asylumaddress": "薛典路西侧广场",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.2,
    "asylumnumber": 1.1,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "市政园林公用局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.438242,
    "latitude": 31.480333
},
{
    "cityname": "宜兴市",
    "asylumname": "宜兴市龙背山森林公园应急避难场所（宜兴市龙背山森林公园）",
    "asylumaddress": "龙背山森林公园应急避难场所（宜兴市龙背山森林公园）",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 550,
    "asylumnumber": 3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "宜兴市森林公园有限公司",
    "managementunithead": null,
    "createtime": "2007",
    "ischeck": "是",
    "longitude": 119.821432,
    "latitude": 31.332935
},
{
    "cityname": "江阴市",
    "asylumname": "江阴市黄山湖公园（山前路与滨江路交叉口西北角）",
    "asylumaddress": "黄山湖公园（山前路与滨江路交叉口西北角）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 13.6,
    "asylumnumber": 3.5,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "园林局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.283505,
    "latitude": 31.931967
},
{
    "cityname": "江阴市",
    "asylumname": "江阴市政府广场（花园路与澄江路交叉口东侧）",
    "asylumaddress": "政府广场（花园路与澄江路交叉口东侧）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 4.9,
    "asylumnumber": 1.3,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "机关管理局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.29053,
    "latitude": 31.924909
},
{
    "cityname": "江阴市",
    "asylumname": "江阴市体育场（中山路与寿山路交叉口东南角）",
    "asylumaddress": "体育场（中山路与寿山路交叉口东南角）",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2.4,
    "asylumnumber": 0.6,
    "basicfacilitymatch": "齐全",
    "commonfacilitymatch": "齐全",
    "comprebasicfacilitymatch": "齐全",
    "managementunit": "体育局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "是",
    "longitude": 120.272266,
    "latitude": 31.918145
},
{
    "cityname": "丰县",
    "asylumname": "丰县复新公园",
    "asylumaddress": "新城区",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 12.3,
    "asylumnumber": 5,
    "basicfacilitymatch": "是",
    "commonfacilitymatch": "是",
    "comprebasicfacilitymatch": "是",
    "managementunit": "丰县住建局",
    "managementunithead": "刘品胜",
    "createtime": "2013.1",
    "ischeck": "否",
    "longitude": 116.615733,
    "latitude": 34.695773
},
{
    "cityname": "沛县",
    "asylumname": "丰县沛公园",
    "asylumaddress": "沛县新城区",
    "placelevel": "中心",
    "placetype": "场地",
    "asylumarea": 68,
    "asylumnumber": 20,
    "basicfacilitymatch": "救灾帐篷、简易活动房屋、医疗救护、应急供水、应急标志",
    "commonfacilitymatch": "应急指挥管理、应急消费设施",
    "comprebasicfacilitymatch": "应急停车场、应急停机坪",
    "managementunit": "沛县园林局",
    "managementunithead": "申颖",
    "createtime": "2013",
    "ischeck": "否",
    "longitude": 116.615733,
    "latitude": 34.695773
},
{
    "cityname": "邳州市",
    "asylumname": "邳州市锦绣广场",
    "asylumaddress": "老城区居住片区",
    "placelevel": "中心",
    "placetype": "场地型",
    "asylumarea": 9.6,
    "asylumnumber": 3.8,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "园林局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.966548,
    "latitude": 34.335894
},
{
    "cityname": "邳州市",
    "asylumname": "邳州市人民公园",
    "asylumaddress": "老城区居住片区",
    "placelevel": "固定",
    "placetype": "场地型",
    "asylumarea": 2,
    "asylumnumber": 0.8,
    "basicfacilitymatch": "基本齐全",
    "commonfacilitymatch": "基本齐全",
    "comprebasicfacilitymatch": "正在完善",
    "managementunit": "园林局",
    "managementunithead": null,
    "createtime": null,
    "ischeck": "否",
    "longitude": 117.970289,
    "latitude": 34.31511
}]
